import React from 'react'

import Svg from '../svg'


export default function AddIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M32,6 L69,6 C83.3594035,6 95,17.6405965 95,32 L95,69 C95,83.3594035 83.3594035,95 69,95 L32,95 C17.6405965,95 6,83.3594035 6,69 L6,32 C6,17.6405965 17.6405965,6 32,6 Z M53.1022907,47.1022907 L53.1022907,17.6022907 L47.1022907,17.6022907 L47.1022907,47.1022907 L17.6022907,47.1022907 L17.6022907,53.1022907 L47.1022907,53.1022907 L47.1022907,82.6022907 L53.1022907,82.6022907 L53.1022907,53.1022907 L82.6022907,53.1022907 L82.6022907,47.1022907 L53.1022907,47.1022907 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
