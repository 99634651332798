import React from 'react'

import Svg from '../svg'


export default function TagIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M72.3728853,23.9678205 C72.3826672,24.1387423 72.3876019,24.3104321 72.3876019,24.4827073 L72.3876019,87.1819657 C72.3876019,92.1525285 68.3581646,96.1819657 63.3876019,96.1819657 L30.3876019,96.1819657 C25.4170391,96.1819657 21.3876019,92.1525285 21.3876019,87.1819657 L21.3876019,24.4827073 C21.3876019,24.3104321 21.3925365,24.1387423 21.4023184,23.9678205 C21.3925336,23.6774973 21.3876019,23.3859436 21.3876019,23.093238 C21.3876019,9.00997691 32.8043407,-2.40676197 46.8876019,-2.40676197 C60.970863,-2.40676197 72.3876019,9.00997691 72.3876019,23.093238 C72.3876019,23.3859436 72.3826702,23.6774973 72.3728853,23.9678205 Z M54.3581646,26.5638008 C58.2634075,22.6585579 58.2634075,16.3269081 54.3581646,12.4216652 C50.4529217,8.51642223 44.1212719,8.51642223 40.216029,12.4216652 C36.3107861,16.3269081 36.3107861,22.6585579 40.216029,26.5638008 C44.1212719,30.4690437 50.4529217,30.4690437 54.3581646,26.5638008 Z" id="Combined-Shape" fill={color || "white"} transform="translate(46.887602, 46.887602) rotate(-45.000000) translate(-46.887602, -46.887602) "></path>
        </g>
    </Svg>
}
