const bemPrototype = {

}

class Bem extends String {
    constructor(className) {
        super(className)
    }

    e(element) {
        return new Bem(this + '__' + element)
    }

    x(extraClassName) {
        return new Bem(this + ' ' + extraClassName)
    }

    m(modifiers) {
        const block = this

        const appliedModifiers = Object.keys(modifiers)
            .map(m => {
                const modifier = m + ''
                const flag = modifiers[modifier]
                if (flag === true) {
                    return block + '--' + convertCamelCaseToKebabCase(modifier)
                }
                if (flag === false) {
                    return null
                }
                if (flag === undefined) {
                    return null
                }
                return block + '--' + convertCamelCaseToKebabCase(modifier) + '-' + convertCamelCaseToKebabCase(flag + '')
            })
            .filter(v => v)
            .join(' ')
        return new Bem([block, appliedModifiers].filter(c => c).join(' '))
    }
}

export default function bem(className) {
    return new Bem(className)
}



function convertCamelCaseToKebabCase(text) {
    text = text += ''
    return text.replace(
        /\.?([A-Z]+)/g,
        (x, y) =>  '-' + y.toLowerCase()
    ).replace(/^-/, '')
}
