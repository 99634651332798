const url = getUrl()

function getUrl() {
    if (window.location.hostname === 'localhost') {
        return 'http://127.0.0.1:8000/api/mapki/'
    }
    if (window.location.hostname.startsWith('127')) {
        return 'http://localhost:8000/api/mapki/'
    }
    if (window.location.hostname.startsWith('192')) {
        return 'http://' + window.location.hostname + ':8000/api/mapki/'
    }
    return 'https://mapki.procrastinationlog.com/api/mapki/'
}


export function makeRequest(endpoint, options = {}, noLog) {
    const headers = {
        'Auth': localStorage.getItem('mapki-token'),
    }
    if (options.contentType === false) {
        // skip
    }
    else if (typeof(options.contentType) === 'string') {
        headers['Content-Type'] = options.contentType
    }
    else {
        headers['Content-Type'] = 'application/json'
    }

    delete options.contentType

    return fetch(url + endpoint, {
        method: 'POST',
        ...options,
        headers,
    }).then(response => {
        if (response.error) {
            throw response.error
        }
        try {
            return response.json()
        }
        catch (error) {
            console.error('response.json()', error)
            throw error
        }
    })
    .then(data => {
        if (data.error) {
            console.warn('data.error', data.error)
            throw data.error
        }
        return data
    })
    .catch(error => {
        throw error
    })
}

export function getGoogleAPIKey() {
    return makeRequest('get-google-api-key', {}, true)
}

export function uploadResources({model, modelId, event, uploading}) {
    const form = new FormData()
    form.append('model', model)
    form.append('model_id', modelId)
    const filenames = []
    if (event.target.files) {
        Array.from(event.target.files).forEach((file) => {
            form.append(`file`, file)
            filenames.push(file.filename)
        })
    }
    if (event.dataTransfer) {
        Array.from(event.dataTransfer.items).forEach(i => {
            if (i.kind === 'file') {
                const file = i.getAsFile()
                form.append(`file`, file)
                filenames.push(file.filename)
            }
        })
    }

    const u = uploading.start(model, modelId, filenames)
    return makeRequest('2/upload-resources', {
        body: form,
        contentType: false,
    })
        .then(response => {
            u.finish()
            return response
        })
        .catch(error => {
            u.fail(error)
            throw error
        })
}
