import React from 'react'

import Svg from './svg'


export default function MergeIcon({width, height, color, ...props}) {
    return <Svg width={width} height={height} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M32,32 L32,24.1123596 C32,14.1091796 40.1091796,6 50.1123596,6 L75.8876404,6 C85.8908204,6 94,14.1091796 94,24.1123596 L94,49.8876404 C94,59.8908204 85.8908204,68 75.8876404,68 L66,68 L66,75.8876404 C66,85.8908204 58.1524069,94 48.4719101,94 L23.5280899,94 C13.8475931,94 6,85.8908204 6,75.8876404 L6,50.1123596 C6,40.1091796 13.8475931,32 23.5280899,32 L32,32 Z"
                fill={color || 'black'}
            ></path>
        </g>
    </Svg>
}
