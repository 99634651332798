import React from 'react'
import './load-more.scss'


export default function LoadMore({page, total, update, spinning}) {
    if (total !== undefined && total !== null && page.end >= total) {
        return null
    }
    return <div
        role="button"
        className="load-more"
        disabled={spinning}
        onClick={() => update(prev => ({...prev, page: {...prev.page, end: prev.page.end + 20}}))}
    >
        {spinning || total === undefined || total === null ?
            <span className="spinner" />
            : "Load more"
        }
    </div>
}
