import React from 'react'

import PopupMenu from './popup-menu'
import Svg from './svg'
import Rating1Icon from './icons/rating-1-icon'
import Rating0Icon from './icons/rating-0-icon'
import RatingNullIcon from './icons/rating-null-icon'
import Rating2Icon from './icons/rating-2-icon'
import Rating3Icon from './icons/rating-3-icon'


export function MainRatingSelect({value, onUpdate}) {
    const props = {width: '16px', height: '16px', color: 'white'}
    return <PopupMenu
        onSelect={onUpdate}
        value={value}
        closeOnSelect
        values={[
            {value: null, message: <span className="producer-rating__item"><RatingNullIcon {...props} /> {getDescriptionForRating(null)}</span>},
            {value: '0', message: <span className="producer-rating__item"><Rating0Icon {...props} /> {getDescriptionForRating(0)}</span>},
            {value: '1', message: <span className="producer-rating__item"><Rating1Icon {...props} /> {getDescriptionForRating(1)}</span>},
            {value: '2', message: <span className="producer-rating__item"><Rating2Icon {...props} /> {getDescriptionForRating(2)}</span>},
            {value: '3', message: <span className="producer-rating__item"><Rating3Icon {...props} /> {getDescriptionForRating(3)}</span>},
        ]}
    >
        {value === 0 || value === '0' ?
            <Rating0Icon {...props} /> : null
        }
        {value === 1 || value === '1' ?
            <Rating1Icon {...props} /> : null
        }
        {value === 2 || value === '2' ?
            <Rating2Icon {...props} /> : null
        }
        {value === 3 || value === '3' ?
            <Rating3Icon {...props} /> : null
        }
        {value === null ?
            <RatingNullIcon {...props} /> : null
        }
    </PopupMenu>
}

export function MainRating({value, ...props}) {
    if (value == 1) {
        return <Rating1Icon {...props} path={iconPaths.orating1}/>
    }
    if (value == 2) {
        return <Rating2Icon {...props} path={iconPaths.orating2}/>
    }
    if (value == 3) {
        return <Rating3Icon {...props} path={iconPaths.orating3}/>
    }
    if (value === 0 || value === '0') {
        return <Rating0Icon {...props} path={iconPaths.orating0}/>
    }
    if (value === null) {
        return <RatingNullIcon {...props} />
    }
    return null
}


export function getDescriptionForRating(rating) {
    if (rating == 3) {
        return 'FAV'
    }
    if (rating == 2) {
        return 'GOOD'
    }
    if (rating == 1) {
        return 'MEH'
    }
    if (rating === 0) {
        return 'BAD'
    }
    return 'NOT RATED'
}

export function getIconForRating(rating) {
    if (rating == 3) {
        return '♥'
    }
    if (rating == 2) {
        return '▲'
    }
    if (rating == 1) {
        return '•'
    }
    if (rating === 0 || rating == '0') {
        return '▼'
    }
    return '?'
}


export const iconPaths = {
    hash: 'M18.6,57 L21.4,43 L0,43 L0,20 L26,20 L30,7.10542736e-15 L54,7.10542736e-15 L50,20 L63,20 L67,-7.10542736e-15 L90,-7.10542736e-15 L86,20 L100,20 L100,43 L81.4,43 L78.6,57 L100,57 L100,80 L74,80 L70,100 L47,100 L51,80 L38,80 L34,100 L10,100 L14,80 L0,80 L0,57 L18.6,57 Z M42.6,57 L55.6,57 L58.4,43 L45.4,43 L42.6,57 Z',
    todoDone0: "M16,0 L84,0 C92.836556,-1.623249e-15 100,7.163444 100,16 L100,84 C100,92.836556 92.836556,100 84,100 L16,100 C7.163444,100 5.41083001e-16,92.836556 0,84 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z M35.1383427,37.9753342 L31.8518519,37.9753342 C29.9904477,37.9753342 28.4814815,39.4980526 28.4814815,41.376421 C28.4814815,42.5531783 29.0737134,43.5903518 29.9738132,44.2009859 C28.802339,44.803382 28,46.032034 28,47.4497903 C28,49.4623278 29.6167495,51.0938118 31.6111111,51.0938118 L32.3333333,51.0938118 C30.7378441,51.0938118 29.4444444,52.398999 29.4444444,54.0090291 L29.4444444,55.4666377 C29.4444444,57.0766677 30.7378441,58.381855 32.3333333,58.381855 L51.1067879,58.381855 C51.6307254,60.9846428 48.4177037,64.5960486 50.3781864,71.3564316 C50.729366,72.5674132 51.9868818,73.2618258 53.1869265,72.9074457 C53.9210503,72.6906547 54.4970578,72.1144531 54.7182955,71.3755608 C55.1493643,69.9358907 55.5333815,68.3628455 55.8703523,66.656427 C56.5230408,63.3512154 58.2263695,59.4593291 60.9803384,54.9807682 L64.1111111,54.9807682 C65.7066004,54.9807682 67,53.6755809 67,52.0655509 L66.9999982,38.7423977 C66.9999982,37.1970241 65.8049648,35.9201672 64.276046,35.8319374 C62.8932455,35.7521397 61.4632155,35.6129734 59.9859561,35.4144386 C59.9568219,35.3771889 59.9259853,35.34006 59.8934462,35.3030517 C58.4916583,33.708731 55.8139167,32.8278056 51.8602213,32.6602756 C50.8872431,32.2334012 49.8204165,32 48.7118156,32 L34.3957438,32 C32.9148154,32 31.7142857,33.2232616 31.7142857,34.7322312 C31.7142857,36.3069557 32.8502965,37.6422301 34.3801211,37.8656711 L34.3801196,37.8656816 C34.6367707,37.9031672 34.8895117,37.939718 35.1383427,37.9753342 Z",
    todoDone1: "M16,0 L84,0 C92.836556,-1.623249e-15 100,7.163444 100,16 L100,84 C100,92.836556 92.836556,100 84,100 L16,100 C7.163444,100 5.41083001e-16,92.836556 0,84 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z M35,51 C38.8659932,51 42,47.6421356 42,43.5 C42,39.3578644 38.8659932,36 35,36 C31.1340068,36 28,39.3578644 28,43.5 C28,47.6421356 31.1340068,51 35,51 Z M65,51 C68.8659932,51 72,47.6421356 72,43.5 C72,39.3578644 68.8659932,36 65,36 C61.1340068,36 58,39.3578644 58,43.5 C58,47.6421356 61.1340068,51 65,51 Z M29.5,60 C28.1192881,60 27,61.1192881 27,62.5 C27,63.8807119 28.1192881,65 29.5,65 L70.5,65 C71.8807119,65 73,63.8807119 73,62.5 C73,61.1192881 71.8807119,60 70.5,60 L29.5,60 Z",
    todoDone2: "M16,0 L84,0 C92.836556,-1.623249e-15 100,7.163444 100,16 L100,84 C100,92.836556 92.836556,100 84,100 L16,100 C7.163444,100 5.41083001e-16,92.836556 0,84 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z M40.2009941,62.9621895 C39.0948369,63.0974897 37.889435,63.2551428 36.5847878,63.4351434 C35.1034065,63.6395338 34,64.9056334 34,66.4010484 C34,67.8364097 35.1635903,69 36.5989516,69 L51.3841493,69 C52.858773,69 54.259014,68.5878351 55.4574488,67.8582934 C58.544097,67.5443984 60.6894295,66.7090203 61.8934462,65.3521591 C61.9259853,65.3154894 61.9568219,65.2787 61.9859561,65.241791 C63.4632155,65.0450721 64.8932455,64.9071787 66.276046,64.8281108 C67.8049648,64.740688 68.9999982,63.4755097 68.9999982,61.9442706 L69,48.7429755 C69,47.1476713 67.7066004,45.8544218 66.1111111,45.8544218 L62.9803384,45.8544218 C60.2263695,41.4168233 58.5230408,37.5605335 57.8703523,34.2855525 C57.5333815,32.5947415 57.1493643,31.0360839 56.7182955,29.6095815 C56.4970578,28.8774473 55.9210503,28.3065159 55.1869265,28.0917078 C53.9868818,27.7405689 52.729366,28.4286303 52.3781864,29.6285358 C50.4177037,36.327086 53.6307254,39.9054606 53.1067879,42.4844425 L34.3333333,42.4844425 C32.7378441,42.4844425 31.4444444,43.777692 31.4444444,45.3729962 L31.4444444,46.817273 C31.4444444,48.4125772 32.7378441,49.7058267 34.3333333,49.7058267 L33.6111111,49.7058267 C31.6167495,49.7058267 30,51.3223886 30,53.3165188 C30,54.7213077 30.802339,55.9387221 31.9738132,56.5356085 C31.0737134,57.1406575 30.4814815,58.1683447 30.4814815,59.334339 C30.4814815,61.1955271 31.9904477,62.7043183 33.8518519,62.7043183 L40.1474984,62.7043183 C40.1614672,62.7915641 40.1793575,62.877588 40.2009941,62.9621895 Z",
    todoDone3: "M16,0 L84,0 C92.836556,-1.623249e-15 100,7.163444 100,16 L100,84 C100,92.836556 92.836556,100 84,100 L16,100 C7.163444,100 5.41083001e-16,92.836556 0,84 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z M58.2173913,31.8942614 C55.3371449,31.8942614 52.6793927,32.8272765 50.5420134,34.4015555 C48.2541564,32.2923966 45.1724802,31 41.7826087,31 C34.7229689,31 29,36.605243 29,43.5196608 C29,57.3152161 47.3206073,66.1029715 49.4579866,69 C51.7458436,66.6378514 71,57.1243996 71,44.4139222 C71,37.1607106 65.2770311,31.8942614 58.2173913,31.8942614 Z",
    todo: 'M16,0 L84,0 C92.836556,-1.623249e-15 100,7.163444 100,16 L100,84 C100,92.836556 92.836556,100 84,100 L16,100 C7.163444,100 5.41083001e-16,92.836556 0,84 L0,16 C-1.082166e-15,7.163444 7.163444,1.082166e-15 16,0 Z M50,55 C52.7614237,55 55,52.7614237 55,50 C55,47.2385763 52.7614237,45 50,45 C47.2385763,45 45,47.2385763 45,50 C45,52.7614237 47.2385763,55 50,55 Z',
    orating0: "M50,100 C22.3857625,100 0,77.6142375 0,50 C0,22.3857625 22.3857625,0 50,0 C77.6142375,0 100,22.3857625 100,50 C100,77.6142375 77.6142375,100 50,100 Z M38.1383427,35.9753342 L34.8518519,35.9753342 C32.9904477,35.9753342 31.4814815,37.4980526 31.4814815,39.376421 C31.4814815,40.5531783 32.0737134,41.5903518 32.9738132,42.2009859 C31.802339,42.803382 31,44.032034 31,45.4497903 C31,47.4623278 32.6167495,49.0938118 34.6111111,49.0938118 L35.3333333,49.0938118 C33.7378441,49.0938118 32.4444444,50.398999 32.4444444,52.0090291 L32.4444444,53.4666377 C32.4444444,55.0766677 33.7378441,56.381855 35.3333333,56.381855 L54.1067879,56.381855 C54.6307254,58.9846428 51.4177037,62.5960486 53.3781864,69.3564316 C53.729366,70.5674132 54.9868818,71.2618258 56.1869265,70.9074457 C56.9210503,70.6906547 57.4970578,70.1144531 57.7182955,69.3755608 C58.1493643,67.9358907 58.5333815,66.3628455 58.8703523,64.656427 C59.5230408,61.3512154 61.2263695,57.4593291 63.9803384,52.9807682 L67.1111111,52.9807682 C68.7066004,52.9807682 70,51.6755809 70,50.0655509 L69.9999982,36.7423977 C69.9999982,35.1970241 68.8049648,33.9201672 67.276046,33.8319374 C65.8932455,33.7521397 64.4632155,33.6129734 62.9859561,33.4144386 C62.9568219,33.3771889 62.9259853,33.34006 62.8934462,33.3030517 C61.4916583,31.708731 58.8139167,30.8278056 54.8602213,30.6602756 C53.8872431,30.2334012 52.8204165,30 51.7118156,30 L37.3957438,30 C35.9148154,30 34.7142857,31.2232616 34.7142857,32.7322312 C34.7142857,34.3069557 35.8502965,35.6422301 37.3801211,35.8656711 L37.3801196,35.8656816 C37.6367707,35.9031672 37.8895117,35.939718 38.1383427,35.9753342 Z",
    orating1: "M50,100 C22.3857625,100 0,77.6142375 0,50 C0,22.3857625 22.3857625,0 50,0 C77.6142375,0 100,22.3857625 100,50 C100,77.6142375 77.6142375,100 50,100 Z M35,51 C38.8659932,51 42,47.6421356 42,43.5 C42,39.3578644 38.8659932,36 35,36 C31.1340068,36 28,39.3578644 28,43.5 C28,47.6421356 31.1340068,51 35,51 Z M65,51 C68.8659932,51 72,47.6421356 72,43.5 C72,39.3578644 68.8659932,36 65,36 C61.1340068,36 58,39.3578644 58,43.5 C58,47.6421356 61.1340068,51 65,51 Z M29.5,60 C28.1192881,60 27,61.1192881 27,62.5 C27,63.8807119 28.1192881,65 29.5,65 L70.5,65 C71.8807119,65 73,63.8807119 73,62.5 C73,61.1192881 71.8807119,60 70.5,60 L29.5,60 Z",
    orating2: "M50,100 C22.3857625,100 0,77.6142375 0,50 C0,22.3857625 22.3857625,0 50,0 C77.6142375,0 100,22.3857625 100,50 C100,77.6142375 77.6142375,100 50,100 Z M39.2009941,61.9621895 C38.0948369,62.0974897 36.889435,62.2551428 35.5847878,62.4351434 C34.1034065,62.6395338 33,63.9056334 33,65.4010484 C33,66.8364097 34.1635903,68 35.5989516,68 L50.3841493,68 C51.858773,68 53.259014,67.5878351 54.4574488,66.8582934 C57.544097,66.5443984 59.6894295,65.7090203 60.8934462,64.3521591 C60.9259853,64.3154894 60.9568219,64.2787 60.9859561,64.241791 C62.4632155,64.0450721 63.8932455,63.9071787 65.276046,63.8281108 C66.8049648,63.740688 67.9999982,62.4755097 67.9999982,60.9442706 L68,47.7429755 C68,46.1476713 66.7066004,44.8544218 65.1111111,44.8544218 L61.9803384,44.8544218 C59.2263695,40.4168233 57.5230408,36.5605335 56.8703523,33.2855525 C56.5333815,31.5947415 56.1493643,30.0360839 55.7182955,28.6095815 C55.4970578,27.8774473 54.9210503,27.3065159 54.1869265,27.0917078 C52.9868818,26.7405689 51.729366,27.4286303 51.3781864,28.6285358 C49.4177037,35.327086 52.6307254,38.9054606 52.1067879,41.4844425 L33.3333333,41.4844425 C31.7378441,41.4844425 30.4444444,42.777692 30.4444444,44.3729962 L30.4444444,45.817273 C30.4444444,47.4125772 31.7378441,48.7058267 33.3333333,48.7058267 L32.6111111,48.7058267 C30.6167495,48.7058267 29,50.3223886 29,52.3165188 C29,53.7213077 29.802339,54.9387221 30.9738132,55.5356085 C30.0737134,56.1406575 29.4814815,57.1683447 29.4814815,58.334339 C29.4814815,60.1955271 30.9904477,61.7043183 32.8518519,61.7043183 L39.1474984,61.7043183 C39.1614672,61.7915641 39.1793575,61.877588 39.2009941,61.9621895 Z",
    orating3: "M50,100 C22.3857625,100 0,77.6142375 0,50 C0,22.3857625 22.3857625,0 50,0 C77.6142375,0 100,22.3857625 100,50 C100,77.6142375 77.6142375,100 50,100 Z M58.2173913,34.3874255 C55.3371449,34.3874255 52.6793927,35.3204406 50.5420134,36.8947196 C48.2541564,34.7855607 45.1724802,33.4931641 41.7826087,33.4931641 C34.7229689,33.4931641 29,39.0984071 29,46.0128249 C29,59.8083802 47.3206073,68.5961356 49.4579866,71.4931641 C51.7458436,69.1310155 71,59.6175637 71,46.9070863 C71,39.6538747 65.2770311,34.3874255 58.2173913,34.3874255 Z",
    oratingNull: "M0,50a50,50 0 1,0 100,0a50,50 0 1,0 -100,0",
    rating0: "M54.5002427,8.94189816 L76.5,8.94189816 C79.5375661,8.94189816 82,11.404332 82,14.4418982 C82,16.7680809 80.7422424,18.8335515 78.8318344,19.9418982 L81,19.9418982 C84.8659932,19.9418982 88,23.0759049 88,26.9418982 C88,29.3638597 86.7699799,31.4985346 84.9005419,32.7553207 C87.3336037,33.9951515 89,36.523921 89,39.4418982 C89,43.5840338 85.6421356,46.9418982 81.5,46.9418982 L80,46.9418982 C83.3137085,46.9418982 86,49.6281897 86,52.9418982 L86,55.9418982 C86,59.2556067 83.3137085,61.9418982 80,61.9418982 L41.0089791,61.9418982 C39.9208011,67.2988667 46.594,74.7317381 42.5222282,88.6457261 C41.7928552,91.1381267 39.1810917,92.567343 36.6886911,91.83797 C35.1639725,91.3917783 33.9676492,90.20586 33.5081556,88.6850973 L33.5081448,88.6851005 C32.6128587,85.7220176 31.8152845,82.4844301 31.1154221,78.972338 C29.7598382,72.1696651 26.2221556,64.1595185 20.5023742,54.9418982 L14,54.9418982 C10.6862915,54.9418982 8,52.2556067 8,48.9418982 L8.00000383,21.5206419 C8.00000383,18.340007 10.4819961,15.7120241 13.657443,15.5304325 C16.5294132,15.3661956 19.4994754,15.0797683 22.5676297,14.6711508 C22.6281392,14.5944848 22.6921845,14.5180673 22.7597656,14.4418982 C26.0130208,10.7752315 32.5751953,8.94189816 42.4462891,8.94189816 L42.5694372,9.18581242 C43.3042906,9.0247376 44.0591563,8.94189816 44.8217773,8.94189816 L54.5,8.94189816 L54.5002427,8.94189816 Z",
    rating1: "M50,91 C27.3563253,91 9,72.6436747 9,50 C9,27.3563253 27.3563253,9 50,9 C72.6436747,9 91,27.3563253 91,50 C91,72.6436747 72.6436747,91 50,91 Z M31,50 C35.9705627,50 40,45.9705627 40,41 C40,36.0294373 35.9705627,32 31,32 C26.0294373,32 22,36.0294373 22,41 C22,45.9705627 26.0294373,50 31,50 Z M69,50 C73.9705627,50 78,45.9705627 78,41 C78,36.0294373 73.9705627,32 69,32 C64.0294373,32 60,36.0294373 60,41 C60,45.9705627 64.0294373,50 69,50 Z M25,61 C22.790861,61 21,62.790861 21,65 C21,67.209139 22.790861,69 25,69 L75,69 C77.209139,69 79,67.209139 79,65 C79,62.790861 77.209139,61 75,61 L25,61 Z",
    rating2: "M42.4997598,92.0284621 L20.5,92.0284621 C17.4624339,92.0284621 15,89.5660282 15,86.5284621 C15,84.2022794 16.2577576,82.1368088 18.1681656,81.0284621 L16,81.0284621 C12.1340068,81.0284621 9,77.8944554 9,74.0284621 C9,71.6065006 10.2300201,69.4718257 12.0994581,68.2150396 C9.66639633,66.9752087 8,64.4464393 8,61.5284621 C8,57.3863265 11.3578644,54.0284621 15.5,54.0284621 L17,54.0284621 C13.6862915,54.0284621 11,51.3421706 11,48.0284621 L11,45.0284621 C11,41.7147536 13.6862915,39.0284621 17,39.0284621 L55.9910209,39.0284621 C57.0791989,33.6714935 50.406,26.2386222 54.4777718,12.3246342 C55.2071448,9.83223355 57.8189083,8.40301726 60.3113089,9.13239023 C61.8360275,9.57858194 63.0323508,10.7645003 63.4918444,12.285263 L63.4918552,12.2852598 C64.3871413,15.2483427 65.1847155,18.4859302 65.8845779,21.9980222 C67.2401618,28.8006952 70.7778444,36.8108418 76.4976258,46.0284621 L83,46.0284621 C86.3137085,46.0284621 89,48.7147536 89,52.0284621 L88.9999962,79.4497184 C88.9999962,82.6303533 86.5180039,85.2583362 83.342557,85.4399278 C80.4705868,85.6041647 77.5005246,85.8905919 74.4323703,86.2992095 C74.3718608,86.3758755 74.3078155,86.452293 74.2402344,86.5284621 C70.9869792,90.1951288 64.4248047,92.0284621 54.5537109,92.0284621 L54.4305628,91.7845479 C53.6957094,91.9456227 52.9408437,92.0284621 52.1782227,92.0284621 L42.5,92.0284621 Z",
    rating3: "M68,10 C83.463973,10 96,21.7783203 96,38 C96,66.4267578 53.8242288,87.7034247 48.8127326,92.9863281 C44.1308541,86.5071753 4,66.8535156 4,36 C4,20.536027 16.536027,8 32,8 C39.4254327,8 46.1757712,10.8904222 51.1872674,15.6075188 C55.8691459,12.0866717 61.6908888,10 68,10 Z",
    ratingNull: 'M50,96 C24.5949015,96 4,75.4050985 4,50 C4,24.5949015 24.5949015,4 50,4 C75.4050985,4 96,24.5949015 96,50 C96,75.4050985 75.4050985,96 50,96 Z M50,85 C69.3299662,85 85,69.3299662 85,50 C85,30.6700338 69.3299662,15 50,15 C30.6700338,15 15,30.6700338 15,50 C15,69.3299662 30.6700338,85 50,85 Z',
}

function Icon({width, path, height, color}) {
    return <Svg width={width} height={height} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="rating-0" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            {path ?
                <path
                    d={path}
                    fill={color || 'black'}
                />
                : null
            }
        </g>
    </Svg>
}

