import React from 'react'

import useProductDropResource from '../hooks/use-product-drop-resource'
import {scoreProductByImportance} from '../model/score-product-by-importance'
import {scoreProductsByImportance} from '../model/score-product-by-importance'
import bm from '../utils/bm'
import {buildMatchFilter} from '../utils/match-filter'
import shouldClick from '../utils/should-click'
import Button from './button'
import get from './get'
import ShelfIcon from './icons/shelf-icon'
import ImageIcon from './image-icon'
import {MainRating} from './main-rating'
import {useObjectStore} from './object-store'
import {M} from './product-editor'
import {WHD} from './product-editor'
import {Rating} from './rating'
import sorted from './sorted'
import './producers-product-list.scss'


export default function ProducersProductList({
    bestDates,
    card,
    limit,
    onSelectProduct,
    onSelectProducts,
    onShowProduct,
    producer,
    products,
    selectedProducts,
    onShowProducts,
    filter,
}) {
    const {state} = useObjectStore()
    products ||= producer.products

    const ps = React.useMemo(() => {
        if (!products) {return []}

        const productsGroupedBySlug = groupBySlug(
            products.filter(buildMatchFilter({model: 'product', filter, state})),
            state
        )
        const ks = Object.keys(productsGroupedBySlug)
            .map(s => ({products: productsGroupedBySlug[s], score: scoreProductsByImportance(productsGroupedBySlug[s], state)}))
        const s = sorted(
            ks,
            p => p.score, -1
        )
        return s
    }, [products, state, filter])

    if ((products || []).length === 0) {
        return null
    }
    const zeroScoring = limit && ps.filter(({score}) => score <= 1).length

    return <ul className={bm("producers-product-list", {card})}>
        {ps.map(({score, products}) => {
            if (score <= 1 && limit) {return null}

            function handleShowProducts(event) {
                if (!onShowProducts) {return}
                if (event.handled) {
                    return
                }
                onShowProducts(event, products)
            }

            const product = products[0]
            const hasYears = products.reduce((acc, v) => acc || v.year, false)

            function getOnClick() {
                if (onSelectProduct) {
                   return event => onSelectProduct(event, product)
                }
                if (!hasYears && onShowProduct) {
                    return event => {
                        if (!shouldClick(event)) {return}
                        onShowProduct(event, product)
                    }
                }
            }

            const onClick = getOnClick()
            let more = 0
            const productsToShow = products
                .map((product, index) => {
                    if (limit === false) {return product}
                    const score = scoreProductByImportance(product, state)
                    if (index > 2 && score < 10000) {
                        more += 1
                        return null
                    }
                    return product
                })
                .filter(p => p)

            return <li
                key={product.id}
                className={bm("producers-product-list__item", {card, selectable: !!onSelectProduct, clickable: !!onClick, selected: selectedProducts && selectedProducts.indexOf(product._slug) !== -1})}
            >
                <div className="producers-product-list__item__header" role={onShowProducts ? "button" : undefined} onClick={handleShowProducts} >
                    {!hasYears ?
                        <WHD product={product} />
                        : null
                    } {product._name} {!hasYears ?
                        <ul className="ratings-list">
                            {product.ratings && product.ratings.map(id => {
                                return <li key={id}>
                                    <Rating id={id} />
                                </li>
                            })}
                        </ul>
                        : null
                    }
                    <div className="producers-product-list__item__aside">
                        {onSelectProducts ?
                            <Button
                                onClick={event => onSelectProducts(event, products)}
                            >
                                <ShelfIcon color="--on-primary" />
                            </Button>
                            : null
                        }
                    </div>
                </div>
                {hasYears ?
                    <ul className="producers-product-list__years-list">
                        {productsToShow.map((product, index) => {
                            return <ProductItem
                                key={product.id}
                                product={product}
                                index={index}
                                limit={limit}
                                onShowProduct={onShowProduct}
                            />
                        })}
                        {more ?
                            <li className="producers-product-list__years-list__item" role={onShowProducts ? "button" : undefined} onClick={handleShowProducts}>
                                <span className="s a producers-product-list__years-more">{more} more</span>
                            </li>
                            : null
                        }
                    </ul>
                    : null
                }
            </li>
        })}
    </ul>
}

function ProductItem({product, index, limit, onShowProduct}) {
    const {dropProps} = useProductDropResource({id: product.id, parentClassName: 'producers-product-list__years-list__item'})

    return <li
        key={product.id}
        className={bm("producers-product-list__years-list__item", {selectable: !!onShowProduct})}
        onClick={onShowProduct ? (event => onShowProduct(event, product)) : undefined}
        role={onShowProduct ? 'button' : undefined}
        {...dropProps}
    >
        <span className="producers-product-list__years-list__item__header">
            {product.year || '-'}
        </span>
        <div className="ratings-list">
            {product.rating !== null ?
                <MainRating value={product.rating} width="22px" color="--primary" width="32px" height="32px" />
                : null
            }
            {product.resources.length > 0 ?
                <ImageIcon width="32px" color="#663299"/>
                : null
            }
            <WHD product={product} />
            {product.ratings.map(id => {
                return <Rating id={id} key={id} />
            })}
        </div>
    </li>
}

function groupBySlug(products, state) {
    const bySlug = {}
    if (!products) {return {}}
    products = products.map(id => get(state, id))
    products.forEach(product => {
        const slug = getSlug(product)
        bySlug[slug] ||= []
        bySlug[slug].push(product)
    })
    Object.keys(bySlug).forEach(slug => {
        bySlug[slug] = sorted(bySlug[slug], p => p.year, -1)
    })
    return bySlug
}

function getSlug(product) {
    if (!product) {return null}
    if (!product.slug) {return null}
    const parts = product.slug.split('-')
    if (parts[0].match(/^\d\d\d\d$/)) {
        product._slug = parts.slice(1).join('-')
        product.year = parts[0]
    }
    else {
        product._slug = product.slug
        product.year = null
    }
    if (product.year && product.name.endsWith(product.year)) {
        product._name = product.name.slice(0, product.name.length - product.year.length).trim()
    }
    else {
        product._name = product.name
    }
    return product._slug
}


