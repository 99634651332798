import React from 'react'

import bem from '../utils/bem'
import PopupMenu from './popup-menu'
import './spinning-context.scss'


const SpinningContextProvider = React.createContext();


export function SpinningContext_({children}) {
    const [spinning, setSpinning] = React.useState([])

    const error = React.useMemo(() => {
        return spinning && spinning.filter(s => s.error).length > 0
    })

    return <SpinningContextProvider.Provider value={{spinning, setSpinning, error}}>
        {children}
    </SpinningContextProvider.Provider>
}

export const SpinningContext = React.memo(SpinningContext_)


export function useSpinning() {
    return React.useContext(SpinningContextProvider)
}
