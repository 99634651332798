import React from 'react'

import {useMerge} from '../components/object-store'
import {useSave} from '../components/object-store'
import bem from '../utils/bem'
import Button from './button'
import MergeIcon from './merge-icon'
import {useObjectStore} from './object-store'
import Portal from './portal'
import './product-selection.scss'
import './selection-shelf.scss'


const SelectionShelfContextProvider = React.createContext();



export function SelectionShelfContext({children}) {
    const [productsIds, setProductsIds_] = React.useState(null)
    const [producersIds, setProducersIds_] = React.useState(null)

    const setProductsIds = React.useCallback(function setProductsIds(update) {
        if (typeof(update) === 'function') {
            setProductsIds_(prev => removeDuplicates(update(prev)))
        }
        else {
            setProductsIds_(removeDuplicates(update))
        }
    }, [])

    const setProducersIds = React.useCallback(function setProducersIds(update) {
        if (typeof(update) === 'function') {
            setProducersIds_(prev => removeDuplicates(update(prev)))
        }
        else {
            setProducersIds_(removeDuplicates(update))
        }
    }, [])

    return <SelectionShelfContextProvider.Provider value={{productsIds, setProductsIds, producersIds, setProducersIds}}>
        {children}
        <SelectionShelf />
    </SelectionShelfContextProvider.Provider>
}

export function useAddProduct() {
    const {setProductsIds} = React.useContext(SelectionShelfContextProvider)

    function addProduct(product) {
        setProductsIds(prev => ([...(prev || []), product.id ? product.id : product]))
    }

    return {setProductsIds, addProduct}
}

export function useAddProducer() {
    const {setProducersIds} = React.useContext(SelectionShelfContextProvider)

    function addProducer(producer) {
        setProducersIds(prev => ([...(prev || []), producer.id ? producer.id : producer]))
    }

    return {setProducersIds, addProducer}
}


export default function SelectionShelf() {
    const {productsIds, producersIds, setProductsIds, setProducersIds} = React.useContext(SelectionShelfContextProvider)
    const {state} = useObjectStore()
    const products = productsIds ? productsIds.map(id => state[id]) : productsIds
    const producers = producersIds ? producersIds.map(id => state[id]) : producersIds

    const [open, setOpen] = React.useState(false)
    const prevRef = React.useRef(products)
    const save = useSave()
    const {merge} = useMerge('product')
    const {merge: mergeProducers} = useMerge('producer')

    if (prevRef.current !== productsIds) {
        setOpen(false)
        prevRef.current = productsIds
    }

    const merges = React.useMemo(() => {
        if (!products) {return {}}
        const merges = {}
        products.forEach(p => {
            merges[p.year] ||= []
            merges[p.year].push(p)
        })
        return merges
    }, [products])
    const years = Object.keys(merges)
    years.sort()

    if (!products && !producers) {return null}

    function handleMerge() {
        const queue = []
        if (producersIds && producersIds.length > 1) {
            queue.push(() => {
                mergeProducers(producersIds[0], producersIds.slice(1))
            })
        }
        years.forEach(year => {
            const name = products[0]._name + ' ' + year
            const year_products = merges[year]
            if (year_products.length > 0) {
                queue.push(() => save('product', {id: year_products[0].id, name}))
            }
            if (year_products.length > 1) {
                queue.push(() => merge(year_products[0].id, year_products.slice(1).map(p => p.id)))
            }
        })
        runSequentially(queue)
            .then(handleCancel)
    }

    function handleCancel() {
        setProductsIds(null)
        setProducersIds(null)
    }

    return <Portal>
        <div className={bem("selection-shelf").m({open})}>
            <div className="selection-shelf__opener" role="button" onClick={() => setOpen(prev => !prev)} />
            <div className="selection-shelf__content">
                {producersIds ?
                    <ul className="selection-shelf__producers-list ">
                        {producersIds.map(id => {
                            return <li key={id}>
                                {state[id].name}
                            </li>
                        })}
                    </ul>
                    : null
                }
                <ul className="selection-shelf__list">
                    {years.map(year => {
                        return <li key={year} className="selection-shelf__list__item">
                            <div className="selection-shelf__list__item__main">{products[0]._name} {year}</div>
                            <div className="selection-shelf__list__item__others">
                                {merges[year].map(p => <span key={p.id}>{p.name}</span>)}
                            </div>
                        </li>
                    })}
                </ul>
                <div className="selection-shelf__footer">
                    <Button onClick={handleMerge}>
                        <MergeIcon /> MERGE
                    </Button>
                    <Button onClick={handleCancel}>
                        CANCEL
                    </Button>
                </div>
            </div>
        </div>
    </Portal>
}


async function runSequentially(functions) {
  for (const func of functions) {
    await func();
  }
}

function removeDuplicates(arr) {
    if (!arr) {return arr}
    if (!arr.filter) {return arr}
    return arr.filter((item, index) => arr.indexOf(item) === index);
}
