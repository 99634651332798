import React from 'react'

import Svg from './svg'
import bm from '../utils/bm'
import './sheet.scss'





export default function Sheet({
    position, onChangePosition, children,
    blockMoveRef, full, onFull, extraClassName, movesTextInput, header, footer, onDrop, onDragEnter, onDragLeave, onDragOver,
    id,
}) {
    const open = position == 'open'

    const ref = React.useRef()
    const dragStartYRef = React.useRef()
    const dragEndYRef = React.useRef()
    const prevYRef = React.useRef()
    const dirRef = React.useRef()
    const sheetStartYRef = React.useRef()
    const yRef = React.useRef()
    const openRef = useUpdatingRef(open)
    const fullRef = useUpdatingRef(full)
    const focusedRef = React.useRef(null)
    const contentRef = React.useRef()
    const positionRef = useUpdatingRef(position)

    const iRef = React.useRef()

    React.useEffect(() => {
        function scroll() {
            if (focusedRef.current) {
                focusedRef.current.blur()
                focusedRef.current = null
            }
        }

        contentRef.current.addEventListener('scroll', scroll)
        return () => {
            if (contentRef.current) {
                contentRef.current.removeEventListener('scroll', scroll)
            }
        }
    }, [])

    function handleIndicatorClick() {
        if (position === 'open') {
            if (contentRef.current.scrollTop === 0) {
                onChangePosition('closed')
            }
            else {
                contentRef.current.scroll({top: 0, behavior: 'smooth'})

            }
        }
        else {
            onChangePosition('open')
        }
    }

    const handlePointerDown = React.useCallback(function handlePointerDown(event) {
        if (!(ref.current.contains(event.target)) || ref.current == event.target) {
            return
        }
        if (isSomethingSelected() ){
            return
        }
        if (isClickable()) {
            return
        }
        if (isHorizontalScrollable(event.target)) {
            return
        }
        if (!isContent()) {
            startDrag()
            return
        }
        if (positionRef.current == 'closed') {
            startDrag()
            return
        }

        if (contentRef.current.scrollTop === 0) {
            dragStartYRef.current = getClientY(event)
            contentRef.current.addEventListener('touchmove', maybeStartDrag)
        }

        function maybeStartDrag(event) {
            contentRef.current.removeEventListener('touchmove', maybeStartDrag)

            if (dragStartYRef.current > getClientY(event)) {
                return
            }

            if (contentRef.current.scrollTop > 0) {
                return
            }
            contentRef.current.addEventListener('touchmove', prevent)
            contentRef.current.addEventListener('scroll', prevent)
            startDrag(event)
        }

        function startDrag() {
            contentRef.current.addEventListener('touchmove', prevent)

            window.addEventListener('touchmove', updateDrag)
            window.addEventListener('touchend', endDrag)

            sheetStartYRef.current = ref.current.getBoundingClientRect().top


            dragStartYRef.current = null
            dragEndYRef.current = null
            ref.current.classList.add('sheet--no-transition')
        }

        function updateDrag(event) {
            if (focusedRef.current) {
                focusedRef.current.blur()
                focusedRef.current = null
            }

            const clientY = getClientY(event)
            if (dragStartYRef.current === undefined || dragStartYRef.current === null) {
                dragStartYRef.current = clientY
                prevYRef.current = dragStartYRef.current
            }
            dragEndYRef.current = clientY
            event.preventDefault()
            const deltaY = clientY - dragStartYRef.current
            const y = sheetStartYRef.current + deltaY
            prevYRef.current = yRef.current
            yRef.current = y
            ref.current.style.top = Math.max(10, Math.min(
                window.innerHeight - 3 * 16, y
            )) + 'px'
        }

        function endDrag(event) {
            ref.current.style.removeProperty('top')
            ref.current.classList.remove('sheet--no-transition')
            window.removeEventListener('touchmove', updateDrag)
            window.removeEventListener('touchend', endDrag)
            contentRef.current.removeEventListener('scroll', prevent)
            contentRef.current.removeEventListener('touchmove', prevent)

            const diff = dragEndYRef.current - dragStartYRef.current


            if (diff > 10) {
                onChangePosition('closed')

                // if (movesTextInput) {
                //     contentRef.current.querySelectorAll('textarea').forEach(e => {
                //         e.disabled = true
                //     })
                // }
            }
            else if (diff < -10) {
                onChangePosition('open')

                // if (movesTextInput) {
                //     contentRef.current.querySelectorAll('textarea').forEach(e => {
                //         e.disabled = false
                //     })
                // }
            }

            event.stopPropagation()
        }


        function isContent() {
            if (event.target === contentRef.current) {
                return true
            }
            return contentRef.current.contains(event.target)
        }

        function isSomethingSelected() {
            const selection = window.getSelection()
            if (selection && selection.toString().length > 0) {
                return true
            }
            return false
        }

        function isClickable() {
            if (!event) {return false}
            return ['A', 'BUTTON'].indexOf(event.target.tagName) !== -1
        }

        function isHorizontalScrollable(element) {
            while (element) {
                if (element.scrollWidth > element.clientWidth) {
                    return true
                }
                element = element.parentElement
                if (element === ref.current) {
                    return false
                }
            }
        }
    }, [])

    return <div
        className={bm("sheet", {open, closed: !open}, extraClassName)}
        onTouchStart={handlePointerDown}
        ref={ref}
        id={id}
        onDrop={onDrop}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
    >
        <SheetIndicator onClick={handleIndicatorClick} >
            {getHeader()}
        </SheetIndicator>
        <div className="sheet__content" ref={contentRef} id={id + '__content'}>
            {getContent()}
        </div>
        {getFooter()}
    </div>

    function getHeader() {
        if (!header) {return null}
        return children[0]
    }

    function getContent() {
        if (!header && !footer) {
            return children
        }
        if (header) {
            return children[1]
        }
        return children[0]
    }

    function getFooter() {
        if (!footer) {
            return null
        }
        if (header) {
            return children[2]
        }
        return children[1]
    }
}



export function SheetIndicator({onClick, children}) {
  return <div className="sheet__indicator">
      <Svg
          viewBox="0 0 100 10"
          width="100%"
          height="100%"
          className="sheet__indicator__icon"
          onClick={onClick}
          role="button"
      >
          <rect x="40" y="4" width="20" height="2" rx="1" fill="#AAAAAA" />
      </Svg>
      {children}
  </div>
}


function useUpdatingRef(value) {
    const ref = React.useRef(value)
    ref.current = value
    return ref
}


function prevent(event) {
    event.preventDefault()
}


function getClientY(event) {
    if (!event) {return null}
    if (event.touches && event.touches[0]) {
        return event.touches[0].clientY
    }
    return event.clientY
}
