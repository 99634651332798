import React from 'react'

import Svg from '../svg'


export default function Rating3Icon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M68,10 C83.463973,10 96,21.7783203 96,38 C96,66.4267578 53.8242288,87.7034247 48.8127326,92.9863281 C44.1308541,86.5071753 4,66.8535156 4,36 C4,20.536027 16.536027,8 32,8 C39.4254327,8 46.1757712,10.8904222 51.1872674,15.6075188 C55.8691459,12.0866717 61.6908888,10 68,10 Z"
            id="Path" fill={color || "white"}></path>
        </g>
    </Svg>
}
