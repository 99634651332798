import React from 'react'

import Svg from '../svg'


export default function Close2Icon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill={color || "white"} points="50 44.3431458 78.2842712 16.0588745 83.9411255 21.7157288 55.6568542 50 83.9411255 78.2842712 78.2842712 83.9411255 50 55.6568542 21.7157288 83.9411255 16.0588745 78.2842712 44.3431458 50 16.0588745 21.7157288 21.7157288 16.0588745 50 44.3431458"></polygon>
        </g>
    </Svg>
}
