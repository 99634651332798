import React from 'react'

import Svg from '../svg'


export default function Rotate180Icon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M84.0243902,5 C90.0860521,5 95,10.1659452 95,16.5384615 L95,83.4615385 C95,89.8340548 90.0860521,95 84.0243902,95 L15.9756098,95 C9.91394787,95 5,89.8340548 5,83.4615385 L5,16.5384615 C5,10.1659452 9.91394787,5 15.9756098,5 L84.0243902,5 Z M31.1785515,31.2478383 C25.5348374,35.8938401 22,42.5486775 22,49.9401158 C22,63.9833654 34.7598846,75.3676685 50.5,75.3676685 C50.6856062,75.3676685 50.8707979,75.3660855 51.0555593,75.3629339 L39.0251263,86.096424 L43.9748737,90.5125631 L64.0386147,72.6117972 L43.9748737,54.7110312 L39.0251263,59.1271703 L50.2262802,69.120782 C38.4783011,68.9900101 29,60.4526226 29,49.9401158 C29,43.7872534 32.2469256,38.3109958 37.2954532,34.8008535 L31.1785515,31.2478383 Z M69.1641151,69.3019467 C75.1893479,64.6397152 79,57.7594009 79,50.0850104 C79,36.0417609 66.2401154,24.6574577 50.5,24.6574577 C50.3143938,24.6574577 50.1292021,24.6590407 49.9444407,24.6621924 L61.9748737,13.9287022 L57.0251263,9.51256313 L36.9613853,27.4133291 L57.0251263,45.314095 L61.9748737,40.8979559 L50.7737198,30.9043442 C62.5216989,31.0351162 72,39.5725036 72,50.0850104 C72,56.1984366 68.7945629,61.6439033 63.8013989,65.1565368 L69.1641151,69.3019467 L69.1641151,69.3019467 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
