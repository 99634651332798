import React from 'react'

import genId from '../utils/gen_id'
import get from './get'
import {useInit} from './init-context'
import MoreIcon from './more-icon'
import {useSave} from './object-store'
import {PopInContent} from './pop-in'
import {usePopIn} from './pop-in'
import PopupMenu from './popup-menu'
import {useObjectStore} from './object-store'
import Modal from './modal'
import './export-modal.scss'


export default function PageSavedQueries({
    model, params, query, setHashParam, total, onExport,
}) {
    const ref = React.useRef(null)
    const popInProps = usePopIn(ref)
    const save = useSave()
    const savedQuery = useInit().saved_query.filter(s => s.id === params.query)[0]
    const {state} = useObjectStore()
    const [exportText, setExportText] = React.useState(null)

    function handleSelect(value) {
        if (value === 'save view…') {
            save('saved_query', {id: params.query, query: {filter: query.filter, sort: query.sort}})
        }
        else if (value === 'create new view…') {
            popInProps.setOpen(true)
        }
        else if (value === 'export…') {
            onExport()
                .then(text => {
                    setExportText(text)
                })
        }
    }

    const unsaved = React.useMemo(() => {
        if (!savedQuery) {return false}
        if (JSON.stringify(query.sort) != JSON.stringify(savedQuery.query.sort)) {
            return true
        }
        if (JSON.stringify(query.filter) != JSON.stringify(savedQuery.query.filter)) {
            return true
        }
        return false
    }, [savedQuery, query])

    return <div className="page__queries">
        <PopupMenu
            value={null}
            values={[unsaved ? 'save view…' : null, 'create new view…', onExport ? 'export…' : null].filter(m => m)}
            innerRef={ref}
            onSelect={handleSelect}
            closeOnSelect
        >
            <MoreIcon />
        </PopupMenu>
        <PopInContent
            {...popInProps}
            placeholder="title"
            onUpdate={name => {
                const id = genId()
                save('saved_query', {id, name, model, query: {filter: query.filter, sort: query.sort}})
                setHashParam('query', id)
            }}
        />
        <h1
            className="page__title"
            role="button"
            onClick={() => {
                window.dispatchEvent(new Event('new_query'))
            }}
        >
            {get(savedQuery, 'name') || (model[0].toUpperCase() +  model.slice(1) + 's')}{unsaved ? '*' : ''} <span className="sub">{total}</span>
        </h1>
        <span className="spacer" />
        {exportText ?
            <ExportModal text={exportText} onRequestClose={() => setExportText(null)}/>
            : null
        }
    </div>
}

function ExportModal({text, onRequestClose}) {
    return <Modal open={true} onRequestClose={onRequestClose}>
        <textarea value={text} className="export-modal__textarea" ref={element => element ? element.select() : null} />
    </Modal>
}
