import React from 'react'

import useDropResource from '../hooks/use-drop-resource'
import bm from '../utils/bm'
import shouldClick from '../utils/should-click'
import {useObject} from './object-store'
import {ProducerData} from './producer-view'
import ProducersProductList from './producers-product-list'
import './producer-list.scss'


export default function ProducerList({
    producers,
    userCoordinates,
    referenceCoordinates,
    onSelectProducer,
    selectable,
    selected,
    selectedProducers,
    panTo,
    updateProducer,
    toggleMergeActive,
    done,
    onSelectProduct,
    onShowProduct,
    onShowProducts,
}) {
    function handleProducerClick(event, id) {
        if (!onSelectProducer) {return}
        if (!shouldClick(event)) {return}
        onSelectProducer(id)
    }

    selectable = !!onSelectProducer && selectable

    return <ul className="producers-list">
        {producers.map(id => {
            return <ProducerItem
                id={id} onShowProduct={onShowProduct} onSelectProduct={onSelectProduct} selected={selected} selectable={selectable} handleProducerClick={handleProducerClick} referenceCoordinates={referenceCoordinates} toggleMergeActive={toggleMergeActive} done={done}
                onShowProducts={onShowProducts}
            />
        })}
    </ul>
}

function ProducerItem({id, selected, selectable, handleProducerClick, referenceCoordinates, toggleMergeActive, done, onSelectProduct, onShowProduct, onShowProducts}) {
    const thisSelected = Array.isArray(selected) ? selected.indexOf(id) !== -1 : selected === id
    const {dropProps} = useDropResource({
        model: 'producer',
        modelId: id,
        parentClassName: 'editor__e',
    })
    return <li
        key={id}
        className={bm("editor__e", {selectable, selected: selectable && thisSelected})}
        onClick={event => handleProducerClick(event, id)}
        {...dropProps}
    >
        <Producer id={id} referenceCoordinates={referenceCoordinates} toggleMergeActive={toggleMergeActive} done={done} onSelectProduct={onSelectProduct} onShowProduct={onShowProduct} onShowProducts={onShowProducts} />
    </li>

}

function Producer({id, referenceCoordinates, toggleMergeActive, done, onSelectProduct, onShowProduct, onShowProducts}) {
    const producer = useObject(id)
    if (!producer) {return null}
    return <>
        <div className="producers-list__producer-inner">
            <div className="producers-list__producer-inner__main">
                <h2 className="producers-list__producer-name">
                    {producer.name}
                </h2>
                <ProducerData
                    producer={producer}
                    done={done}
                    toggleMergeActive={toggleMergeActive}
                />
            </div>
            <div className="producers-list__producer-inner__side">
                <Distance
                    coordinates={producer}
                    referenceCoordinates={referenceCoordinates}
                />
            </div>
        </div>
        <ProducersProductList
            producer={producer}
            limit={1}
            onSelectProduct={onSelectProduct}
            onShowProduct={onShowProduct}
            onShowProducts={onShowProducts}
        />
    </>
}


function Distance({coordinates, referenceCoordinates}) {
    const distance = getDistance(coordinates, referenceCoordinates)
    if (distance === null) {
        return null
    }
    if (distance < 1) {
        return <span>{Math.round(distance * 1000)}m</span>
    }
    if (distance < 100) {
        return <span>{distance.toFixed(2)}km</span>
    }
    return <span>{Math.round(distance)}km</span>


    function getDistance(c1, c2) {
        if (!c1 || !c2) {return null}
        if (c1.coordinate_latitudine === undefined) {return null}
        if (c2.coordinate_latitudine === undefined) {return null}
        if (c1.coordinate_longitudine === undefined) {return null}
        if (c2.coordinate_longitudine === undefined) {return null}
        if (c1.coordinate_latitudine === null) {return null}
        if (c2.coordinate_latitudine === null) {return null}
        if (c1.coordinate_longitudine === null) {return null}
        if (c2.coordinate_longitudine === null) {return null}

        var R = 6371.0710
        var rlat1 = toRadians(c1.coordinate_latitudine) // Convert degrees to radians
        var rlat2 = toRadians(c2.coordinate_latitudine) // Convert degrees to radians
        var difflat = rlat2 - rlat1 // Radian difference (latitudes)
        var difflon = toRadians(c2.coordinate_longitudine - c1.coordinate_longitudine) // Radian difference (longitudes)
        return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))

        function toRadians(value) {
            return value * Math.PI / 180
        }
    }
}
