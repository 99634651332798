import React from 'react'

import Svg from '../svg'


export default function RatingNullIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M50,96 C24.5949015,96 4,75.4050985 4,50 C4,24.5949015 24.5949015,4 50,4 C75.4050985,4 96,24.5949015 96,50 C96,75.4050985 75.4050985,96 50,96 Z M50,85 C69.3299662,85 85,69.3299662 85,50 C85,30.6700338 69.3299662,15 50,15 C30.6700338,15 15,30.6700338 15,50 C15,69.3299662 30.6700338,85 50,85 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
