import React from 'react'

import Svg from './svg'


export default function EditIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M81.2309858,8.05071425 L30.7131576,58.5685425 L27.3033009,72.5850009 L41.3197593,69.1751442 L91.871125,18.6237785 C93.8667007,22.3038075 95,26.5195556 95,31 L95,69 C95,83.3594035 83.3594035,95 69,95 L31,95 C16.6405965,95 5,83.3594035 5,69 L5,31 C5,16.6405965 16.6405965,5 31,5 L69,5 C73.421615,5 77.5854392,6.10373365 81.2309858,8.05071425 Z" id="Combined-Shape" fill={color || "#000000"}></path>
        <path d="M81.2309858,8.05071425 L87.2817001,2 L97.8883018,12.6066017 L91.871125,18.6237785 C89.4404414,14.1413694 85.7304736,10.45376 81.2309858,8.05071425 Z" id="Combined-Shape" fill={color || "#000000"}></path>
        </g>
    </Svg>
}
