import React from 'react'
import Page from './components/page'
import {useList} from './components/object-store'
import {ObjectContext} from './components/object-store'
import {useField} from './components/object-store'
import {useSaveField} from './components/object-store'
import Button from './components/button'
import DeleteIcon from './components/icons/delete-icon'
import './page-trash.scss'
import {useDeletes} from './components/object-store'
import {useSaveListener} from './components/object-store'


const producerFilter = {operator: 'AND', items: [{field: 'producer._status', operator: '=', operand: 'TRASHED'}]}
const productFilter = {operator: 'AND', items: [{field: 'product._status', operator: '=', operand: 'TRASHED'}]}


const interestedInSaved = {
    models: {producer: true, product: true},
}

export default function PageTrash() {
    const {ids: producersIds, refresh: refreshProducers} = useList('producer', {filter: producerFilter})
    const {ids: productsIds, refresh: refreshProducts} = useList('product', {filter: productFilter})
    const deletes = useDeletes()

    function refresh() {
        refreshProducers()
        refreshProducts()
    }
    useSaveListener(interestedInSaved, refresh)

    function handleDelete() {
        deletes('producer', producersIds)
        deletes('product', productsIds)
    }

    return <Page extraClassName="page-templates" top={<div />}>
        {producersIds.length ? <h2>Producers</h2> : null}
        {producersIds.map(id => {
            return <Item id={id} key={id} model="producer" onRestore={refresh} />
        })}
        {productsIds.length ? <h2>Products</h2> : null}
        {productsIds.map(id => {
            return <Item id={id} key={id} model="product" onRestore={refresh} />
        })}
        {producersIds.length + productsIds.length <= 0 ?
            <div className="empty">empty</div> :
            <div className="spacer spacer--end" >
                <Button onClick={handleDelete}>
                    <DeleteIcon color="--on-primary" /> Delete all
                </Button>
            </div>
        }
    </Page>
}


function Item({model, id, ...props}) {
    return <ObjectContext model={model} id={id}>
        <Item_ id={id} {...props} />
    </ObjectContext>
}

function Item_({id, onRestore}) {
    const [name] = useField('name')
    const [status, setStatus] = useSaveField('_status')
    return <div className="page-trash__item">
        {name}
        <Button
            onClick={() => {
                setStatus(null)
            }}
        >
            restore
        </Button>
    </div>
}
