import React from 'react'

import Svg from '../svg'


export default function GroupedIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M15.9756098,5 L84.0243902,5 C90.0860521,5 95,10.1659452 95,16.5384615 L95,83.4615385 C95,89.8340548 90.0860521,95 84.0243902,95 L15.9756098,95 C9.91394787,95 5,89.8340548 5,83.4615385 L5,16.5384615 C5,10.1659452 9.91394787,5 15.9756098,5 Z M19,17 C15.6862915,17 13,19.6862915 13,23 C13,26.3137085 15.6862915,29 19,29 L81,29 C84.3137085,29 87,26.3137085 87,23 C87,19.6862915 84.3137085,17 81,17 L19,17 Z M43,44 C39.6862915,44 37,46.6862915 37,50 C37,53.3137085 39.6862915,56 43,56 L81,56 C84.3137085,56 87,53.3137085 87,50 C87,46.6862915 84.3137085,44 81,44 L43,44 Z M43,71 C39.6862915,71 37,73.6862915 37,77 C37,80.3137085 39.6862915,83 43,83 L79,83 C82.3137085,83 85,80.3137085 85,77 C85,73.6862915 82.3137085,71 79,71 L43,71 Z M19,56 C22.3137085,56 25,53.3137085 25,50 C25,46.6862915 22.3137085,44 19,44 C15.6862915,44 13,46.6862915 13,50 C13,53.3137085 15.6862915,56 19,56 Z M19,83 C22.3137085,83 25,80.3137085 25,77 C25,73.6862915 22.3137085,71 19,71 C15.6862915,71 13,73.6862915 13,77 C13,80.3137085 15.6862915,83 19,83 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
