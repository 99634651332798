import React from 'react'
import ModalSheet from './modal-sheet'
import bm from '../utils/bm'
import AddIcon from './icons/add-icon'

import './adding-modal-sheet.scss'


const defaultPlaceholder = `Name...
Address...

Note...
- Product...
- Product...`


export default function AddingModalSheet({open, onRequestClose, placeholder, onAdd, slot, initial}) {
    placeholder ||= defaultPlaceholder
    const ref = React.useRef()
    const [text, setText] = React.useState('')
    const [spinning, setSpinning] = React.useState(false)

    React.useEffect(() => {
        if (open && ref.current) {
            setText(initial || '')
            ref.current.focus()
            setTimeout(() => ref.current.focus(), 0)
        }
    }, [open])

    return <ModalSheet open={open} onRequestClose={onRequestClose} slot={slot} footer extraClassName="adding-modal-sheet">
        <textarea
            className="adding-modal-sheet__textarea"
            placeholder={placeholder}
            autoFocus
            autofocus="true"
            ref={ref}
            value={text}
            onChange={event => setText(event.target.value)}
        />
        <div className={bm('app__master__bottom-bar', {open})}>
            <button className="button" onClick={() => {
                setText('')
                onRequestClose()
            }}>Cancel</button>
            <button disabled={spinning || text.length === 0} className="button" onClick={() => {
                setSpinning(true)
                onAdd(text)
                    .then(() => {
                        setSpinning(false)
                        setText('')
                        onRequestClose()
                    })
                    .catch(error => {
                        setSpinning(false)
                    })
            }}>
                <AddIcon width="16px" height="16px" color="white"/> Add
            </button>
        </div>
    </ModalSheet>
}
