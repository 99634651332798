import React from 'react'

import bem from '../utils/bem'
import useHash from '../utils/use-hash'
import Button from './button'
import DuplicateWarningIcon from './duplicate-warning-icon'
import Modal from './modal'
import {useList} from './object-store'
import {useMerge} from './object-store'
import {useObject} from './object-store'
// import {makeRequest} from '../http'
// import get from './get'


export default function DuplicateWarning({model, id, operator, fields}) {
    const object = useObject(id)
    const [duplicates, setDuplicates] = React.useState([])
    const [merging, setMerging] = React.useState(false)
    const {setHashParam} = useHash()
    const [spinning, setSpinning] = React.useState(false)

    const {merge} = useMerge(model)

    const values = fields.map(field => object[field.split('.')[1]])
    const filter = React.useMemo(() => {
        return {
            operator: operator || 'AND',
            items: fields
                .map(field => {
                    const operand = object[field.split('.')[1]]
                    if (operand === undefined) {return null}
                    return {field, operator: '=', operand}
                })
                .filter(f => f)
        }
    }, [fields, operator, ...values])

    const {total, ids} = useList(model, {filter, x: 'DuplicateWarning'})

    if (!object) {return null}

    if (total < 2) {
        return null
    }

    function handleClick(main) {
        const others = ids.filter(id => id !== main)
        setSpinning(true)
        merge(main, others)
            .then(response => {
                if (model === 'producer') {
                    setHashParam('edit', `producer/${response.order[0]}`)
                }
                else {
                    const producerId = response.objects[0].producer_id
                    setHashParam('edit', `producer/${producerId}/product/${response.order[0]}`)
                }
                setSpinning(false)
                setMerging(false)
            })
            .catch(error => {
                console.warn('error', error)
                setSpinning(false)
                setMerging(false)
            })
    }

    const className = bem('duplicates-warning')
    return <>
        <Button
            danger
            square
            onClick={() => setMerging(true)}
        >
            <DuplicateWarningIcon color="white"/>
        </Button>
        {merging ?
            <Modal onRequestClose={() => setMerging(false)} open={merging}>
                <div className={className}>
                    {spinning ? <div><span className="spinner"/></div> : null}
                    {ids.map(id => {
                        return <ProducerView id={id} key={id} className={className} onClick={handleClick} />
                    })}
                </div>
            </Modal>
            : null
        }
    </>
}

function ProducerView({id, className, onClick}) {
    const producer = useObject(id)
    return <div
        key={producer.id} className={className.e('i')}
        onClick={() => onClick(id)}
    >
        <div>{id}</div>
        <h5>{producer.name}</h5>
        <div>{producer.id}</div>
        <div>{producer._created_at}</div>
        <div>{producer._updated_at}</div>
        <div>{producer.note}</div>
    </div>
}
