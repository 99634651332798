import React from 'react'


export default function Paging({page, total}) {
    if (!total) {return null}
    const showing = Math.min(total, page.end - page.start)
    return <div className="paging">
        {showing} / {total}
    </div>
}
