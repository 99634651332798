import React from 'react'

import Svg from './svg'


export default function SortIcon({width, height, color}) {
    return <Svg width={width} height={height} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill={color || 'black'} points="83.1287893 47.2 17.5818888 47.2 11.3553391 41.9316498 50.3553391 8.93333333 89.3553391 41.9316498 83.1287893 47.2"></polygon>
            <polygon id="Path" fill={color || 'black'} points="83.1287893 52.8 89.3553391 58.0683502 50.3553391 91.0666667 11.3553391 58.0683502 17.5818888 52.8"></polygon>
        </g>
    </Svg>
}
