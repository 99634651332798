import React from 'react'

import Svg from './svg'


export default function ProductsIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" fill={color || "#000000"} x="6" y="6" width="42" height="42" rx="8"></rect>
        <rect id="Rectangle" fill={color || "#000000"} x="52" y="6" width="42" height="42" rx="8"></rect>
        <rect id="Rectangle" fill={color || "#000000"} x="6" y="52" width="42" height="42" rx="8"></rect>
        <rect id="Rectangle" fill={color || "#000000"} x="52" y="52" width="42" height="42" rx="8"></rect>
        </g>
    </Svg>
}
