import React from 'react'

import Svg from '../svg'


export default function Rating0Icon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M54.5002427,8.94189816 L76.5,8.94189816 C79.5375661,8.94189816 82,11.404332 82,14.4418982 C82,16.7680809 80.7422424,18.8335515 78.8318344,19.9418982 L81,19.9418982 C84.8659932,19.9418982 88,23.0759049 88,26.9418982 C88,29.3638597 86.7699799,31.4985346 84.9005419,32.7553207 C87.3336037,33.9951515 89,36.523921 89,39.4418982 C89,43.5840338 85.6421356,46.9418982 81.5,46.9418982 L80,46.9418982 C83.3137085,46.9418982 86,49.6281897 86,52.9418982 L86,55.9418982 C86,59.2556067 83.3137085,61.9418982 80,61.9418982 L41.0089791,61.9418982 C39.9208011,67.2988667 46.594,74.7317381 42.5222282,88.6457261 C41.7928552,91.1381267 39.1810917,92.567343 36.6886911,91.83797 C35.1639725,91.3917783 33.9676492,90.20586 33.5081556,88.6850973 L33.5081448,88.6851005 C32.6128587,85.7220176 31.8152845,82.4844301 31.1154221,78.972338 C29.7598382,72.1696651 26.2221556,64.1595185 20.5023742,54.9418982 L14,54.9418982 C10.6862915,54.9418982 8,52.2556067 8,48.9418982 L8.00000383,21.5206419 C8.00000383,18.340007 10.4819961,15.7120241 13.657443,15.5304325 C16.5294132,15.3661956 19.4994754,15.0797683 22.5676297,14.6711508 C22.6281392,14.5944848 22.6921845,14.5180673 22.7597656,14.4418982 C26.0130208,10.7752315 32.5751953,8.94189816 42.4462891,8.94189816 L42.5694372,9.18581242 C43.3042906,9.0247376 44.0591563,8.94189816 44.8217773,8.94189816 L54.5,8.94189816 L54.5002427,8.94189816 Z" id="Path" fill={color || "white"}></path>
        </g>
    </Svg>
}
