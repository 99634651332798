import {Wrapper} from "@googlemaps/react-wrapper"
import React from 'react'

import {getGoogleAPIKey} from '../http'


export default function GoogleMapWrapper(props) {
    const apiKey = useGoogleAPIKey()

    if (!apiKey) {
        return null
    }

    const render = (status) => {
        if (status === 'SUCCESS') {
            return props.children
        }
        if (status === 'LOADING') {
            return <div className="spinner-centered">
                <span className="spinner spinner--size-1000 spinner--primary" />
            </div>
        }
        return <div className="init-context">
            <h1>{status}</h1>
        </div>
    }

    return <Wrapper apiKey={apiKey} render={render} />
}

function useGoogleAPIKey() {
    const [apiKey, setApiKey] = React.useState(null)

    React.useEffect(() => {
        getGoogleAPIKey()
            .then(setApiKey)
    }, [])

    return apiKey
}
