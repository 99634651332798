import React from 'react'

import Svg from '../svg'


export default function TemplateIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M63.177046,72.0232558 L88.8125,72.0232558 C91.6447485,72.0232558 94.0325435,73.7934252 94.7670885,76.2093023 L95,76.2093023 L95,90.8604651 L89.375,90.8604651 L89.375,94 L10.625,94 L10.625,90.8604651 L5,90.8604651 L5,76.2093023 L5.23291155,76.2093023 C5.96745655,73.7934252 8.35525147,72.0232558 11.1875,72.0232558 L36.7505995,72.0232558 C48.7380586,59.3609191 29.75,27.9913388 29.75,21.7906977 C29.75,11.9651667 38.3125542,4 48.875,4 C49.1266941,4 49.489635,4.01660936 49.9638228,4.04982809 C50.3123551,4.0168616 50.6751044,4 51.0526455,4 C61.6150914,4 70.1776455,11.9651667 70.1776455,21.7906977 C70.1776455,27.9913388 51.1895869,59.3609191 63.177046,72.0232558 L63.177046,72.0232558 Z M51,71.5393268 C50.6542937,71.5393268 50.321104,71.5126786 50,71.4606732 C49.678896,71.5126786 49.3457063,71.5393268 49,71.5393268 L50.9999912,71.5393268 L51,71.5393268 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
