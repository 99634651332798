import useDropResource from './use-drop-resource'
import {useWantHaveDoneWorkflow} from '../components/product-editor'


export default function useProductDropResource({
    id, parentClassName
}) {
    const {increaseDone} = useWantHaveDoneWorkflow({productId: id})
    return useDropResource({
        model: 'product',
        modelId: id,
        parentClassName,
        onDrop: (e) => {
            if (e.shiftKey) {
                increaseDone()
            }
        }
    })
}
