import React from 'react'
import bem from '../utils/bem'
import Navigation from './navigation'
import './page.scss'



export default function Page({children, top, extraClassName}) {
    const className = bem("page")
    return <div className={className}>
        <div className={className.e('content').x(extraClassName)}>
            {children}
        </div>
        <div className={className.e('top-bar')}>
            {top}
            <span className={className.e('nav')}>
                <Navigation />
            </span>
        </div>
    </div>
}

