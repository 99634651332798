import React from 'react'
import {Select} from './popup-select'
import {makeRequest} from '../http'
import useDebounce from '../hooks/use-debounce'
import get from './get'
import {useObject} from './object-store'



export default function ProducerSelect({value, ...props}) {
    const [query, setQuery] = React.useState('')
    const [options, setOptions] = React.useState([])

    const producer = useObject(value)

    const valueOption = {
        value: get(producer, 'id'),
        label: get(producer, 'name'),
    }

    const debouncedQuery = useDebounce(query)

    React.useEffect(() => {
        const controller = new AbortController()
        if (debouncedQuery.length < 3) {
            return
        }
        makeRequest('2/producer_list', {
            signal: controller.signal,
            body: JSON.stringify({
                page: {start: 0, end: 100},
                fields: {name: true, id: true},
                filter: {
                    operator: 'AND',
                    items: [{
                        field: 'producer.name',
                        operator: 'LIKE',
                        operand: query,
                    }]
                },
                sort: [{
                    field: 'producer.name',
                    dir: 'DESC',
                }]
            }),
        })
            .then(response => {
                console.log('response', response)
                const objects = {}
                response.objects.forEach(o => objects[o.id] = o)
                const options = response.order
                    .map(id => objects[id])
                    .map(p => {
                        return {value: p.id, label: p.name}
                    })
                setOptions(options)
            })
        return () => controller.abort()
    }, [debouncedQuery])

    return <Select
        query={query}
        setQuery={setQuery}
        options={options}
        placeholder="select producer…"
        valueOption={valueOption}
        value={value}
        {...props}
    />
}
