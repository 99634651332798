import React from 'react'

import Svg from './svg'


export default function MoreIcon({width, height, color}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="more" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" fill={color || "#FFFFFF"} cx="50" cy="50" r="14"></circle>
            <circle id="Oval" fill={color || "#FFFFFF"} cx="82" cy="50" r="14"></circle>
            <circle id="Oval" fill={color || "#FFFFFF"} cx="18" cy="50" r="14"></circle>
        </g>
    </Svg>
}
