import React from 'react'

import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'
import AddIcon from './icons/add-icon'
import Button from './button'
import {align} from './popup-menu'
import Portal from './portal'
import SaveIcon from './icons/save-icon'


export default function PopIn({onUpdate}) {
    const ref = React.useRef()
    const {open, setOpen, sref, value, setValue} = usePopIn(ref)

    return <>
        <Button onClick={() => setOpen(true)} innerRef={ref}>
            <AddIcon color="white" />
        </Button>
        <PopInContent
            open={open}
            setOpen={setOpen}
            sref={sref}
            ref={ref}
            rref={ref}
            value={value}
            setValue={setValue}
            onUpdate={onUpdate}
        />
    </>
}

export function usePopIn(ref) {
    const [open, setOpen] = React.useState(false)
    const sref = React.useRef()
    useCloseOnClickOutside(open, () => setOpen(false), sref, ref)
    const [value, setValue] = React.useState('')

    return {open, setOpen, sref, rref: ref, value, setValue}
}

export function PopInContent({open, setOpen, sref, rref, value, setValue, onUpdate, placeholder}) {
    if (!open) {return null}
    return <Portal parent={rref.current}>
        <div
            ref={element => {
                sref.current = element
                align(rref.current, element)
            }}
            className="popup-select tag-editor"
        >
            <input
                type="text"
                value={value}
                ref={element => {
                    if (element) {
                        setTimeout(() => element.focus(), 0)
                    }
                }}
                autoFocus
                placeholder={placeholder || "value"}
                className="popup-editor-input"
                onChange={event => setValue(event.target.value)}
            />
            <Button
                onClick={() => {
                    onUpdate(value)
                    setValue('')
                    setOpen(false)
                }}
            >
                <AddIcon
                    color="--on-primary"
                />
            </Button>
        </div>
    </Portal>
}
