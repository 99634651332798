import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import AddIcon from './components/icons/add-icon'
import Button from './components/button'
import DeleteIcon from './components/icons/delete-icon'
import {useInit} from './components/init-context'
import {ObjectContext} from './components/object-store'
import {useDelete} from './components/object-store'
import {useField} from './components/object-store'
import {useFocusBlurSaveField} from './components/object-store'
import {useList} from './components/object-store'
import {useSaveListener} from './components/object-store'
import {useSave} from './components/object-store'
import Page from './components/page'
import genId from './utils/gen_id'

import './page-templates.scss'


const interestedInSaved = {
    models: {template: true},
}
const sort = [{
    field: "template.name",
    dir: "ASC",
}]

export default function PageTemplates() {
    const init = useInit()
    const save = useSave()

    const {ids, refresh} = useList('template', {sort})
    const [main, setMain] = React.useState(null)
    useSaveListener(interestedInSaved, refresh)

    const mIds = React.useMemo(() => {
        if (main) {
            return [main, ...ids.filter(i => i !== main)]
        }
        return ids
    }, [ids, main])


    return <Page extraClassName="page-templates" top={<div />}>
        <div>
            <Button
                onClick={() => {
                    const id = genId()
                    save('template', {id, model: 'product', name: '', data: null})
                    setMain(id)
                }}
            >
                <AddIcon color="white" />
            </Button>
        </div>
        {mIds.map((id, index) => {
            return <ObjectContext model="template" id={id} key={id} >
                <TemplateEditor index={index} />
            </ObjectContext>
        })}
    </Page>
}


function TemplateEditor({index}) {
    const nameProps = useFocusBlurSaveField('name')
    const [model] = useField('model')
    const dataProps = useFocusBlurSaveField('data')
    const remove = useDelete()
    const [id] = useField('id')

    return <div className="template-editor">
        <div className="spacer spacer--between">
            <h3>{model}</h3>
            <Button onClick={() => remove('template', id)}>
                <DeleteIcon color="white" />
            </Button>
        </div>
        <input type="text" {...nameProps} placeholder="name" autoFocus={index == 0} className="template-editor__name"/>
        <TextareaAutosize
            {...dataProps}
            placeholder="data"
        />
    </div>
}
