import React from 'react'

import Svg from './svg'


export default function HamburgerIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect id="Rectangle" fill={color || "#000000"} x="10" y="10" width="80" height="20"></rect>
            <rect id="Rectangle" fill={color || "#000000"} x="10" y="70" width="80" height="20"></rect>
            <rect id="Rectangle" fill={color || "#000000"} x="10" y="40" width="80" height="20"></rect>
        </g>
    </Svg>
}
