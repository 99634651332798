import React from 'react'

import Svg from '../svg'


export default function Rating2Icon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M42.4997598,92.0284621 L20.5,92.0284621 C17.4624339,92.0284621 15,89.5660282 15,86.5284621 C15,84.2022794 16.2577576,82.1368088 18.1681656,81.0284621 L16,81.0284621 C12.1340068,81.0284621 9,77.8944554 9,74.0284621 C9,71.6065006 10.2300201,69.4718257 12.0994581,68.2150396 C9.66639633,66.9752087 8,64.4464393 8,61.5284621 C8,57.3863265 11.3578644,54.0284621 15.5,54.0284621 L17,54.0284621 C13.6862915,54.0284621 11,51.3421706 11,48.0284621 L11,45.0284621 C11,41.7147536 13.6862915,39.0284621 17,39.0284621 L55.9910209,39.0284621 C57.0791989,33.6714935 50.406,26.2386222 54.4777718,12.3246342 C55.2071448,9.83223355 57.8189083,8.40301726 60.3113089,9.13239023 C61.8360275,9.57858194 63.0323508,10.7645003 63.4918444,12.285263 L63.4918552,12.2852598 C64.3871413,15.2483427 65.1847155,18.4859302 65.8845779,21.9980222 C67.2401618,28.8006952 70.7778444,36.8108418 76.4976258,46.0284621 L83,46.0284621 C86.3137085,46.0284621 89,48.7147536 89,52.0284621 L88.9999962,79.4497184 C88.9999962,82.6303533 86.5180039,85.2583362 83.342557,85.4399278 C80.4705868,85.6041647 77.5005246,85.8905919 74.4323703,86.2992095 C74.3718608,86.3758755 74.3078155,86.452293 74.2402344,86.5284621 C70.9869792,90.1951288 64.4248047,92.0284621 54.5537109,92.0284621 L54.4305628,91.7845479 C53.6957094,91.9456227 52.9408437,92.0284621 52.1782227,92.0284621 L42.5,92.0284621 Z" id="Combined-Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
