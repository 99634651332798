export default function sorted(array, key, d = 1) {
    key = key || (x => x)
    array = [...array]
    array.sort((a, b) => {
        const aValue = key(a)
        const bValue = key(b)
        if (aValue === bValue) {
            return 0
        }
        if (aValue === undefined) {
            return -1 * d
        }
        if (bValue === undefined) {
            return 1 * d
        }
        if (aValue < bValue) {
            return -1 * d
        }
        else if (aValue == bValue) {
            return 0
        }
        else {
            return 1 * d
        }
    })
    return array
}