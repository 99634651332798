export default function get(o, ...keys) {
    let value = o
    for (let i = 0; i < keys.length; i++) {
        if (value === undefined || value === null) {
            return value
        }
        const key = keys[i]
        value = value[key]
    }
    return value
}

export function cget(...keys) {
    return (o) => get(o, ...keys)
}