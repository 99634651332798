import React from 'react'

import Button from './button'
import AddIcon from './icons/add-icon'


export default function AddButton(props) {
    return <Button {...props}>
        <AddIcon color="--on-primary" />
    </Button>
}
