import React from 'react'

import bem from '../utils/bem'
import genId from '../utils/gen_id'
import useHash from '../utils/use-hash'
import AddIcon from './icons/add-icon'
import Button from './button'
import CloseIcon from './close-icon'
import get from './get'
import ShelfIcon from './icons/shelf-icon'
import Modal from './modal'
import {ObjectContext} from './object-store'
import {useGet} from './object-store'
import {useList} from './object-store'
import {useObjectStore} from './object-store'
import {useObject} from './object-store'
import {useSaves} from './object-store'
import OnBlurInput from './on-blur-input'
import {AddTagButton} from './product-editor'
import {Mini} from './product-editor'
import {useAddProduct} from './selection-shelf'
import {ProductsTagEditor} from './tag'
import {ProductsTagSelect} from './tag'
import {Tag} from './tag'
import './products-editor-modal.scss'


export default function ProductsEditorModal({onSelectProducts}) {
    const {params, setHashParam} = useHash()
    const productsIds = (params.edit && params.edit.startsWith('products/')) ?
        params.edit.split('products/')[1].split('/')[0].split(',')
        : null

    const filter = React.useMemo(() => {
        if (!productsIds) {return {active: false, x: 'ProductsEditorModal'}}
        return {
            filter: {
                operator: 'AND',
                items: [{
                    field: 'product.id', operator: 'IN', operand: productsIds
                }]
            },
            x: 'ProductsEditorModal',
        }
    }, [productsIds && productsIds.join(',')])

    useList('product', filter)
    // useGet('producer', productsIds)
    // const producer = useObject(producerId)
    // useGet('product', productId)
    // const product = useObject(productId)


    function close() {
        setHashParam('edit', null)
    }

    const className = bem('producer-editor-modal')

    const firstProduct = useObject(get(productsIds, 0))

    if (!productsIds || productsIds.length === 0) {return null}
    if (!firstProduct) {return null}

    return <Modal
        open={params.edit}
        onRequestClose={close}
        innerExtraClassName={className}
    >
        <div className={className.e('top-bar')}>
            <h2>
                {firstProduct._name}
            </h2>
            <span className={className.e('top-bar-buttons')}>
                <CloseIcon
                    width="1.5rem" height="1.5rem" onClick={close} role="button" className="icon-button"
                />
            </span>
        </div>
        <div className={className.e('content').m({}).x('products-editor-content')}>
            <BulkEditor productsIds={productsIds} onSelectProducts={onSelectProducts} />
            {productsIds.map(id => {
                return <ProductEditor_ id={id} key={id} />
            })}
        </div>
    </Modal>
}

export function ProductsEditor({productsIds, onSelectProducts}) {
    const className = bem('producer-editor-modal')
    return <div className="editor">
        <div className={className.e('content').m({}).x('products-editor-content')}>
            <BulkEditor productsIds={productsIds} onSelectProducts={onSelectProducts} />
            {productsIds.map(id => {
                return <ProductEditor_ id={id} key={id} />
            })}
        </div>
    </div>
}


function ProductEditor_({id}) {
    const product = useObject(id)
    if (!product) {return null}
    return <div className="products-editor-modal__item">
        <ObjectContext model="product" id={id}>
            <Mini product={product} />
        </ObjectContext>
    </div>
}


function BulkEditor({productsIds, onSelectProducts}) {
    const {state} = useObjectStore()
    const saves = useSaves()
    const {params, setHashParam} = useHash()
    const {setProductsIds} = useAddProduct()

    function handleAdd() {
        const sharedTags = findSharedTags(productsIds, state)
        const tagsToAdd = Object.keys(sharedTags).filter(tag => sharedTags[tag])
        const year = get(state, productsIds[0], 'year')
        const name = get(state, productsIds[0], '_name') + ' ' + (parseInt(year, 10) + 1)
        const producer_id = get(state, productsIds[0], 'producer_id')
        const object = {id: genId(), name, producer_id}
        const tagObjects = tagsToAdd.map(tag => ({tag, product_id: object.id, value: '', id: genId()}))
        saves('product_tag', tagObjects)
        object.tags = tagObjects.map(t => t.id)
        saves('product', [object])
        setHashParam('edit', `producer/${producer_id}/products/${object.id + ',' + params.edit.split('products/')[1]}`)
    }

    return <div className="products-editor-modal__bulk-editor">
        <div className="products-editor-modal__bulk-editor__main">
            <OnBlurInput
                value={get(state, productsIds[0], '_name')}
                onChange={event => {
                    const name = event.target.value
                    const updates = productsIds.map(id => {
                        return {id, name: name + ' ' + state[id].year}
                    })
                    saves('product', updates)
                }}
            />
            <Button onClick={handleAdd}>
                <AddIcon color="--on-primary"/>
            </Button>
            <Button onClick={event => setProductsIds(prev => ([...(prev || []), ...productsIds]))}>
                <ShelfIcon color="--on-primary"/>
            </Button>
        </div>
        <BulkTagEditor productsIds={productsIds}/>
    </div>
}


function BulkTagEditor({productsIds}) {
    const {state} = useObjectStore()

    const tags = React.useMemo(() => {
        return findSharedTags(productsIds, state)
    }, [productsIds.join(','), state])

    return <div className="products-editor-modal__tags">
        <ProductsTagSelect
            productsIds={productsIds}
            ValueComponent={AddTagButton}
        />
        {Object.keys(tags).map(tag => {
            return <ProductsTagEditor productsIds={productsIds} tag={tag} key={tag} extraClassName={tags[tag] ? '' : 'tag--partial'} />
        })}
    </div>
}

function findSharedTags(productsIds, state) {
    const tags = {}
    if (!productsIds) {return tags}
    const products = productsIds.map(id => state[id])
    products.forEach(p => {
        if (!p) {return}
        if (!p.tags) {return}
        p.tags.forEach(t => {
            const tag = state[t]
            if (!tag) {return}
            tags[tag.tag] ||= 0
            tags[tag.tag] += 1
        })
    })
    Object.keys(tags).forEach(t => {
        tags[t] = tags[t] === products.length
    })
    return tags
}
