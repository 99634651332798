import React from 'react'

import bem from '../utils/bem'
import './query-builder.scss'


export default function RatingQuery({value, onChange}) {
    const values = React.useMemo(() => {
        return [
            // '*',
            1, 2, 3, 4, 5,
        ]
    }, [])
    return <div className="switcher switcher--w100">
        {values.map(v => {
            return <button
                key={v}
                onClick={() => {
                    if (v == value) {
                        onChange('*')
                    }
                    else {
                        onChange(v)
                    }
                }}
                className={bem('switcher__item').m({w100: true, active: (v !== '*' && v >= value),}).x('button')}
            >
                {v}
            </button>
        })}
    </div>
}
