import React from 'react'
import Portal from './components/portal'
import bem from './utils/bem'
import './merger.scss'
import Button from './components/button'
import {ObjectContext} from './components/object-store'
import {useFocusBlurSaveField} from './components/object-store'
import useHash from './utils/use-hash'
import {useField} from './components/object-store'
import {Tag} from './components/tag'

export default function Merger({selection, cancelMerge, model}) {
    const className = bem('merger')
    const [open, setOpen] = React.useState(false)
    return <Portal>
        <div
            className={className.m({open, visible: !!selection})}
            onClick={open ? undefined : (() => setOpen(prev => !prev))}
        >
            <div
                className={className.e('expander').m({open})}
                role="button"
                onClick={open ? () => setOpen(prev => !prev) : undefined}>
            </div>
            <div className={className.e('content').m({visible: !!selection})}>
                <ObjectContext model={model} id={selection}>
                    <Editor selection={selection} className={className}/>
                </ObjectContext>
                <Button
                    onClick={() => {
                        cancelMerge()
                        setOpen(false)
                    }}
                >
                    done
                </Button>
            </div>
        </div>
    </Portal>
}

function Editor({selection, className}) {
    const {setHashParam} = useHash()
    const nameProps = useFocusBlurSaveField('name')
    return <div className={className.e('editor')}>
        <span
            className="a"
            role="button" tabIndex="0"
            onClick={() => setHashParam('edit', 'producer/' + selection)}
        >{selection}</span>
        <input {...nameProps} />
    </div>
}