export default function shouldClick(event) {
    if (event.target.tagName === 'A') {return false}
    if (event.target.tagName === 'BUTTON') {return false}
    if (event.target.tagName === 'INPUT') {return false}
    if (event.target.tagName === 'TEXTAREA') {return false}
    if (event.target.role === 'button') {return false}
    if (event.handled) {return false}
    if (window.getSelection().toString().length > 0) {
        return false
    }

    return true
}
