import React from 'react'


export default function OnBlurInput({value, onChange, autoFocus, ...props}) {
    const [value_, setValue_] = React.useState(value)
    const prevRef = React.useRef(value)

    if (prevRef.current !== value) {
        setValue_(value)
        prevRef.current = value
    }

    function handleChange(event) {
        setValue_(event.target.value)
    }

    return <input type="text" value={value_ || ''} onChange={handleChange} onBlur={onChange} {...props} />
}