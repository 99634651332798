import React from 'react'
import Page from './components/page'
import bem from './utils/bem'
import {Tag} from './components/tag'
import {TagSelect} from './components/tag'
import useLocalStorageState from './utils/use-local-storage-state'
import Button from './components/button'
import DeleteIcon from './components/icons/delete-icon'
import './page-product-preset.scss'


export default function PageProductPreset() {
    const className = bem('page-product-preset')

    const [name, setName] = useLocalStorageState('product-preset-name', '')
    const [tags, setTags] = useLocalStorageState('product-preset-tags', [])


    return <Page top={<div />}>
        <div className={className.x("editor")}>
            <input
                type="text" placeholder="name"
                value={name}
                onChange={event => setName(event.target.value)}
            />
            <ul className={className.e('tag-list')}>
                {tags.map(t => {
                    return <li key={t}>
                        <Tag tag={t} extraClassName={className.e('tag')}/>
                        <Button onClick={() => {
                            setTags(prev => prev.filter(tag => tag !== t))
                        }}>
                            <DeleteIcon color="white"/>
                        </Button>
                    </li>
                })}
            </ul>
            <TagSelect
                model="product_tag"
                onUpdate={tag => {
                    setTags(prev => ([...prev, tag]))
                }}
            />
        </div>
    </Page>
}