import React from 'react'

import Svg from './svg'


export default function DuplicateWarningIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M30.3636364,28 L30.3636364,21.7589377 C30.3636364,10.8463777 39.2100141,2 50.1225741,2 L78.2410623,2 C89.1536223,2 98,10.8463777 98,21.7589377 L98,49.8774259 C98,60.7899859 89.1536223,69.6363636 78.2410623,69.6363636 L70,69.6363636 L70,48 C70,36.954305 61.045695,28 50,28 L30.3636364,28 Z" id="Combined-Shape" fill={color || "#000000"}></path>
        <path d="M22,30 L48,30 C59.045695,30 68,38.954305 68,50 L68,78 C68,89.045695 59.045695,98 48,98 L22,98 C10.954305,98 2,89.045695 2,78 L2,50 C2,38.954305 10.954305,30 22,30 Z M35,87 C38.3137085,87 41,84.3137085 41,81 C41,77.6862915 38.3137085,75 35,75 C31.6862915,75 29,77.6862915 29,81 C29,84.3137085 31.6862915,87 35,87 Z M29,43 L29,69 L41,69 L41,43 L29,43 Z" id="Combined-Shape" fill={color || "#000000"}></path>
        </g>
    </Svg>
}
