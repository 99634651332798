import React from 'react'

import Svg from '../svg'


export default function LocateIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M35.2745401,76.9537466 C23.5243881,71.8507861 14.9408366,60.8270569 13.2891064,47.6477797 C13.0996379,46.4659126 13,45.2514641 13,44 C13,43.8330728 13.0011054,43.6664039 13.0033093,43.5 C13.0011054,43.3335961 13,43.1669272 13,43 C13,22.5654643 29.5654643,6 50,6 C70.4345357,6 87,22.5654643 87,43 C87,43.1669272 86.9988946,43.3335961 86.9966907,43.5 C86.9988946,43.6664039 87,43.8330728 87,44 C87,45.2514641 86.9003621,46.4659126 86.7108936,47.6477797 C85.0591634,60.8270569 76.4756119,71.8507861 64.7254599,76.9537466 C59.0674536,82.3778153 53.582827,88.1327525 50,95 C46.417173,88.1327525 40.9325464,82.3778153 35.2745401,76.9537466 L35.2745401,76.9537466 Z" id="Path" fill={color || "white"}></path>
        </g>
    </Svg>
}
