import React from 'react'

import get from './get'
import {MainRating} from './main-rating'
import Switcher from './switcher2'


export default function ProducerRatingFilterComponent({onUpdate, item}) {
    return <Switcher
        grow
        value={get(item, 'operand')}
        options={[null, 0, 1, 2, 3].map(value => {
            return {value, message: <MainRating value={value} color="--on-primary" />}
        })}
        onSwitch={(_, v) => {
            const operator = v === null ? 'IS NULL' : '='
            const operand = v
            onUpdate({...item, operator, operand})
        }}
    />
}
