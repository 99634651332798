import React from 'react'

import Svg from '../svg'


export default function DownIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill={color || "white"} points="87.8155195 31 95 37.0576849 50 75 5 37.0576849 12.1844805 31"></polygon>
        </g>
    </Svg>
}
