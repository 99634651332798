import React from 'react'
import useUpdatingRef from '../hooks/use-updating-ref'


export function useHash_() {
    const [hash, setHash_] = React.useState(window.location.hash + '')
    const hashRef = useUpdatingRef(hash)


    const params = React.useMemo(() => {
        return parseURLQuery(hash)
    }, [hash])

    function setHash(hash, params) {
        window.location.hash = paramsToHash({...params, '#': hash})
    }

    function setHashParam(param, value) {
        const hash = window.location.hash
        const nextParams = parseURLQuery(hash)
        nextParams[param] = value
        window.location.hash = paramsToHash(nextParams)
    }

    React.useEffect(() => {
        function update() {
            if (window.location.hash != hashRef.current) {
                setHash_(window.location.hash + '')
            }
        }
        window.addEventListener('hashchange', update)
        return () => window.removeEventListener('hashchange', update)
    }, [])


    return {hash, setHash, setHashParam, params}
}


function paramsToHash(params) {
    if (!params) {return ''}
    const parts = [params['#']]
    Object.keys(params).forEach(key => {
        if (key === '#') {
            return
        }
        const value = params[key]
        if (value) {
            parts.push(key + '=' + encodeURIComponent(value))
        }
    })
    return parts.join('&')
}


export function parseURLQuery(hash) {
    if (!hash) {return {}}
    const query = hash
    if (!query) {return {}}
    const params = {}
    var keysValues = query.split('&')
    keysValues.forEach(keyValue => {
        const split = keyValue.split('=')
        const key = decodeURIComponent(split[0])
        const value = decodeURIComponent(split[1])
        if (key.startsWith('#')) {
            params['#'] = key
        }
        else {
            params[key] = value
        }
    })
    return params
}

export function parseLocationSearch() {
    return parseURLQuery(window.location.search)
}


export function useParsedLocationSearch() {
    return React.useMemo(() => {
        return parseLocationSearch()
    }, [window.location.search])
}


export function formatURLQuery(object) {
    const parts = []
    Object.keys(object).forEach(key => {
        parts.push([key, object[key]].join('='))
    })
    return '?' + parts.join('&')
}

function getBeforeHash(text) {
    if (!text) {return text}
    return text.split('#')[0]
}


const RouterContextProvider = React.createContext();


export function RouterContext({children}) {
    const value = useHash_()
    return <RouterContextProvider.Provider value={value}>
        {children}
    </RouterContextProvider.Provider>
}



export default function useHash() {
    return React.useContext(RouterContextProvider)
}


export function buildInitialFilter(params) {
    if (!params) {return {}}
    if (!params.filter) {return {}}
    const filter = JSON.parse(decodeURI(params.filter))
    return filter || {}
}