import React from 'react'

import Svg from '../svg'


export default function SaveIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M71,5.81980515 L95,29.8198052 L95,81 C95,88.7319865 88.7319865,95 81,95 L19,95 C11.2680135,95 5,88.7319865 5,81 L5,19 C5,11.2680135 11.2680135,5 19,5 L20,5 L20,30 C20,32.209139 21.790861,34 24,34 L67,34 C69.209139,34 71,32.209139 71,30 L71,5.81980515 Z M24,44 C21.790861,44 20,45.790861 20,48 L20,81 C20,83.209139 21.790861,85 24,85 L74,85 C76.209139,85 78,83.209139 78,81 L78,48 C78,45.790861 76.209139,44 74,44 L24,44 Z" id="Combined-Shape" fill={color || "white"}></path>
        <path d="M65,5 L65,20 C65,22.209139 63.209139,24 61,24 L56,24 C53.790861,24 52,22.209139 52,20 L52,5 L65,5 Z" id="Combined-Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
