import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import './query.scss'



export default function Query({name, value, onChange, onFocus}) {
    const [value_, setValue_] = React.useState(null)

    function onFocus(event) {
        setValue_(value)
    }

    function onBlur(event) {
        onChange(event.target.value)
        setValue_(null)
    }

    return <div className="query__item">
        <TextareaAutosize value={value_ === null ? value : value_} onChange={event => setValue_(event.target.value)} onFocus={onFocus} onBlur={onBlur} placeholder={name} onFocus={onFocus} />
    </div>
}
