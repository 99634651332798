import React from 'react'

import Svg from '../svg'


export default function RotateCcwIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M84.0243902,5 C90.0860521,5 95,10.1659452 95,16.5384615 L95,83.4615385 C95,89.8340548 90.0860521,95 84.0243902,95 L15.9756098,95 C9.91394787,95 5,89.8340548 5,83.4615385 L5,16.5384615 C5,10.1659452 9.91394787,5 15.9756098,5 L84.0243902,5 Z M49.9444407,30.6621924 L61.9748737,19.9287022 L57.0251263,15.5125631 L36.9613853,33.4133291 L57.0251263,51.314095 L61.9748737,46.8979559 L50.7737198,36.9043442 C62.5216989,37.0351162 72,45.5725036 72,56.0850104 C72,66.6790408 62.3741221,75.2671993 50.5,75.2671993 C38.6258779,75.2671993 29,66.6790408 29,56.0850104 L22,56.0850104 C22,70.12826 34.7598846,81.5125631 50.5,81.5125631 C66.2401154,81.5125631 79,70.12826 79,56.0850104 C79,42.0417609 66.2401154,30.6574577 50.5,30.6574577 C50.3143938,30.6574577 50.1292021,30.6590407 49.9444407,30.6621924 L49.9444407,30.6621924 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
