import React from 'react'

import Button from './components/button'
import CloseIcon from './components/close-icon'
import Page from './components/page'
import {AddTagButton} from './components/product-editor'
import Query from './components/query'
import {ProducerTagSelect} from './components/tag'
import {Tag} from './components/tag'
import bem from './utils/bem'
import genId from './utils/gen_id'
import {useSave} from './components/object-store'
import {makeRequest} from './http'
import LocateIcon from './components/icons/locate-icon'
import DuplocateIcon from './components/duplicate-icon'
import EditIcon from './components/edit-icon'
import DeleteIcon from './components/icons/delete-icon'
import useLocalStorageState from './utils/use-local-storage-state'
import {useUpdate} from './components/object-store'
import {useSaves} from './components/object-store'
import {useDeletes} from './components/object-store'
import AddIcon from './components/icons/add-icon'

import './page-multi-add.scss'


const tagged = {}

// layout
// feedback when adding
export default function PageMultiAdd() {
    const [spinning, setSpinning] = React.useState(false)
    const [query, setQuery] = useLocalStorageState('multi-add-query', '')
    const [tags, setTags] = React.useState([])
    const [producers, setProducers] = React.useState([])
    const [commonSearch, setCommonSearch] = React.useState('')
    const className = bem('page-multi-add')
    const save = useSave(false)
    const saves = useSaves(false)
    const deletes = useDeletes()

    async function handlePaste() {
        const text = await navigator.clipboard.readText()
        setQuery(prev => {
            return [prev, text].filter(s => s).join('\n')
        })
    }

    function tagProducers(tags) {
        producers.forEach(p => tagProducer(p.id, tags))
    }

    function tagProducer(id, tags) {
        saves('producer_tag', tags.map(tag => {
            const object = {tag, producer_id: id, id: genId(), value: ''}
            tagged[tag] ||= []
            tagged[tag].push(object.id)
            return object
        }))
            .catch(error => {
                // ignore
            })
    }

    function untagProducers(tag) {
        if (tagged[tag] && tagged[tag].length > 0) {
            deletes('producer_tag', tagged[tag])
        }
    }

    function handleSplit() {
        setSpinning(true)
        const newProducers = query.split('\n')
            .filter(s => s)
            .map(s => {
                return {id: genId(), name: s}
            })
        Promise.all(newProducers.map(locateProducer))
            .then(() => {
                setSpinning(false)
                setQuery('')
            })
            .catch(() => {
                console.log('W')
                setSpinning(false)
            })
    }

    function locateProducer(producer) {
        return makeRequest('g-get-results-from-query', {
            body: JSON.stringify(producer.name + ' ' + commonSearch)
        })
            .then(response => {
                const googlePlace = response[0]
                if (!googlePlace) {
                    return
                }
                return makeRequest('2/producer_list', {
                    body: JSON.stringify({
                        page: {start: 0, end: 1},
                        filter: {
                            operator: 'AND',
                            items: [{
                                field: 'producer.google_place_id',
                                operator: '=',
                                operand: googlePlace.google_place_id,
                            }]
                        },
                    }),
                })
                    .then(response => {
                        const prev = response.total === 1 ?
                            response.objects[0]
                            : {}
                        console.log('prev', prev)
                        const [coordinate_latitudine, coordinate_longitudine] = googlePlace.coordinate.split(', ')
                        const next = {
                            ...prev,
                            ...producer,
                            id: prev.id || producer.id,
                            address: googlePlace.address,
                            google_place_id: googlePlace.google_place_id,
                            coordinate_latitudine,
                            coordinate_longitudine,
                            name: googlePlace.name,
                        }
                        setProducer(next, prev.id)
                        tagProducer(next.id, tags)
                        return save('producer', next)
                    })

            })
            .catch(error => {
                console.error('X', error)
                setProducer({...producer, error})
            })
    }

    function setProducer(producer, prevId) {
        setProducers(prev => {
            let found = false
            const id = prevId || producer.id
            const next = prev.map(p => {
                if (p.id !== id) {
                    return p
                }
                found = true
                return {...p, ...producer}
            })
            if (found) {
                return next
            }
            else {
                return [...next, producer]
            }
        })
    }

    return <Page top={<div />}>
        <div className={className}>
            <input
                type="text" placeholder="Search..."
                value={commonSearch}
                onChange={event => setCommonSearch(event.target.value)}
            />
            {producers && producers.length > 0 ?
                <div className="editor__e">
                    {producers.map(producer => {
                        const c = className.e('producer')
                        return <div key={producer.id} className={c}>
                            <input
                                type="text"
                                value={producer.name}
                                onChange={event => setProducer({...producer, name: event.target.value})}
                            />
                            {producer.error ?
                                <span className={c.e('error')}>
                                    {producer.error}
                                </span>
                                : null
                            }
                            <div className={c.e('bottom')}>
                                <span className={c.e('address')}>
                                    {producer.address} {producer.note}
                                </span>
                                <Button
                                    onClick={() => locateProducer(producer)}
                                    disabled={spinning}
                                >
                                    <LocateIcon color="white" />
                                </Button>
                                <a
                                    href={"#producers&edit=producer/" + producer.id}
                                    target="_blank"
                                    className="button"
                                >
                                    <EditIcon color="white"/>
                                </a>
                                <Button>
                                    <DeleteIcon color="white"/>
                                </Button>
                            </div>
                        </div>
                    })}
                </div>
                : null
            }
            <div className={className.e('adder')}>
                <Query
                    name="Add..."
                    value={query}
                    onChange={value => setQuery(value)}
                />
                <div className={className.e('bar')}>
                    <ProducerTagSelect
                        ValueComponent={AddTagButton}
                        onUpdate={tag => {
                            setTags(prev => {
                                const next = [tag, ...(prev || []).filter(t => t !== tag)]
                                tagProducers(next)
                                return next
                            })
                        }}
                    />
                    {tags && tags.length > 0 ?
                        <div className={className.e('tags')}>
                            {tags.map(tag => {
                                return <Tag
                                    key={tag}
                                    tag={tag}
                                    onClick={() => {
                                        setTags(prev => prev.filter(t => t !== tag))
                                        untagProducers(tag)
                                    }}
                                    icon={<CloseIcon />}
                                />
                            })}
                        </div>
                        : null
                    }
                    <span style={{flexGrow: 1}}/>
                    <Button onClick={handlePaste}>
                        <DuplocateIcon color="white" />
                    </Button>
                    <Button
                        onClick={handleSplit}
                        disabled={spinning}
                    >
                        <AddIcon color="white" />
                    </Button>
                </div>
            </div>
            <div className={className.e('actions')}>
                <ProductRatingAdder
                    producers={producers}
                />

            </div>
        </div>
        {spinning ?
            <div className={className.e('spinner-container')}>
                <div className="spinner" />
            </div>
            : null
        }
    </Page>
}


function ProductRatingAdder({producers}) {
    const [product, setProduct] = React.useState('')
    const [source, setSource] = React.useState('')
    const [canonical_rating, setCanonical_rating] = React.useState('')
    const [normalized_rating, setNormalized_rating] = React.useState('')
    const [timestamp, setTimestamp] = React.useState(new Date().toISOString().split('T')[0])
    const saves = useSaves()

    function addProductRating() {
        if (!product) {return}
        const products = producers.map(p => {
            return {id: genId(), name: product, producer_id: p.id}
        })
        saves('product', products)
        setProduct('')
        if (source && canonical_rating && normalized_rating && timestamp) {
            const ratings = products.map(p => {
                return {source, canonical_rating, normalized_rating, timestamp, product_id: p.id, id: genId()}
            })
            saves('product_rating', ratings)
            setSource('')
            setCanonical_rating('')
            setNormalized_rating('')
        }

    }

    return <>
        <input type="text" placeholder="product..." value={product} onChange={e => setProduct(e.target.value)} />
        <input type="text" placeholder="source..." value={source} onChange={e => setSource(e.target.value)} />
        <input type="text" placeholder="canonical..." value={canonical_rating} onChange={e => setCanonical_rating(e.target.value)} />
        <input type="text" placeholder="normalized..." value={normalized_rating} onChange={e => setNormalized_rating(e.target.value)} />
        <input type="text" placeholder="YYYY-MM-DD HH:MM" value={timestamp} onChange={e => setTimestamp(e.target.value)} />
        <Button
            onClick={addProductRating}
        >
            <AddIcon color="white" />
        </Button>
    </>
}