import React from 'react'


export default function useCloseOnClickOutside(open, requestClose, ref, ignoreRef) {
    const closeOnClickOutside = event => {
        if (shouldClose(event.target, ref.current, ignoreRef.current)) {
            requestClose()
        }
    }

    const closeOnEsc = event => {
        if (event.key === 'Escape') {
            requestClose()
        }
    }

    React.useEffect(() => {
        if (!open) {return}
        window.addEventListener('mousedown', closeOnClickOutside)
        window.addEventListener('touchdown', closeOnClickOutside)
        window.addEventListener('keyup', closeOnEsc)
        window.addEventListener('resize', requestClose)
        return () => {
            window.removeEventListener('mousedown', closeOnClickOutside)
            window.removeEventListener('touchdown', closeOnClickOutside)
            window.removeEventListener('keyup', closeOnEsc)
            window.removeEventListener('resize', requestClose)
        }
    }, [open])

    return ref
}


function shouldClose(target, elementToClose, ignore) {
    if (!elementToClose) {return false}
    if (ignore && (ignore.contains(target) || ignore == target)) {
        return false
    }
    if (elementToClose.contains(target)) {
        return false
    }
    while (target.parentElement) {
        if (target.__portalParent && elementToClose.contains(target.__portalParent)) {
            return false
        }
        if (target.__portalParent) {
            target = target.__portalParent
        }
        else {
            target = target.parentElement
        }
    }
    return true
}

function addScrollEventListener(element, requestClose) {
    if (!element) {return}
    element = getParent(element)

    const toRemove = []
    while (element) {
        toRemove.push(element)
        element.addEventListener('scroll', requestClose)
        element = getParent(element)
    }

    return () => {
        toRemove.forEach(e => e.removeEventListener('scroll', requestClose))
    }

    function getParent(element) {
        if (element === window) {return null}
        const parent = element.__portalParent || element.parentElement
        if (parent && parent.classList.contains('modal')) {return null}
        return parent || window
    }

}