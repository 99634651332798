import React from 'react'
import {objectStoreController} from '../components/object-store'
import get from '../components/get'
import {useObjectStore} from '../components/object-store'
import {useSave} from '../components/object-store'


export default function useMergeName(model) {
    const save = useSave()

    const [selected, setSelected] = React.useState(null)

    function addToMergeName(id) {
        if (!selected) {
            setSelected(id)
        }
        else if (selected === id) {
            setSelected(null)
        }
        else {
            merge(selected, id)
        }
    }

    function merge(mainId, otherId) {
        const main = get(objectStoreController, 'state', mainId)
        if (!main) {return}
        const name = main.name
        if (!name) {return}

        let nameToUse = name

        if (main.slug) {
            const parts = main.slug.split('-')
            if (parts[0].match(/^\d\d\d\d$/)) {
                const year = parts[0]
                if (nameToUse.endsWith(' ' + year)) {
                    nameToUse = nameToUse.slice(0, -5)
                }
            }
        }
        const other = get(objectStoreController, 'state', otherId)
        if (other) {
            const parts = other.slug.split('-')
            if (parts[0].match(/^\d\d\d\d$/)) {
                const year = parts[0]
                nameToUse += ' ' + year
            }

        }
        save(model, {id: otherId, name: nameToUse})
    }

    return {
        mergeNameSelected: selected,
        mergeNameActive: !!selected,
        addToMergeName: selected ? addToMergeName : undefined,
        addToMergeNameAlways: addToMergeName,
    }
}
