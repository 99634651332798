import React from 'react'

import Svg from './svg'


export default function NoteIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M94.5063291,56 L82,56 C67.6405965,56 56,67.6405965 56,82 L56,95 L32,95 C17.6405965,95 6,83.3594035 6,69 L6,32 C6,17.6405965 17.6405965,6 32,6 L69,6 C83.3594035,6 95,17.6405965 95,32 L95,69 C95,83.3594035 83.3594035,95 69,95 L56,95 L94.5063291,56 L94.5063291,56 Z" id="Path" fill={color || "#000000"}></path>
        </g>
    </Svg>
}
