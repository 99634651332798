export function scoreProductsByImportance(products, state) {
    let score = 0
    products.forEach(product => {
        score += scoreProductByImportance(product, state)
    })
    return score
}


export function scoreProductByImportance(product, state) {
    let score = 0
    if (product.rating !== null && product.rating !== undefined) {
        score += 100000
    }
    if (product.ratings) {
        product.ratings.forEach(id => {
            const rating = state[id]
            if (rating.source !== 'Vinous') {
                score += 100
            }
            else if (rating.normalized_rating >= 90) {
                score += rating.normalized_rating - 89
            }
        })
    }
    if (product.tags) {
        product.tags.forEach(id => {
            const tag = state[id]
            if (!tag) {return 0}
            if (tag.tag === 'want') {
                score += 20000
            }
            if (tag.tag === 'have') {
                score += 30000
            }
            if (tag.tag === 'done') {
                score += 10000
            }
        })
    }
    return score
}
