import React from 'react'


export default function useUserCoordinates(errorValue) {
    const [userCoordinates, setCoordinates] = React.useState()
    React.useEffect(() => {
        getUserCoordinates()
    }, [])

    function getUserCoordinates() {
        if (userCoordinates) {
            setCoordinates({...userCoordinates})
        }
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (a) => resolve({coordinate_latitudine: a.coords.latitude, coordinate_longitudine: a.coords.longitude}),
                reject,
            )
        })
            .then(coordinates => {
                setCoordinates(coordinates)
                return coordinates
            })
            .catch(error => {
                setCoordinates(errorValue)
            })
    }
    return {userCoordinates, refreshUserCoordinates: getUserCoordinates}
}
