import React from 'react'

import useDropResource from '../hooks/use-drop-resource'
import bm from '../utils/bm'
import useHash from '../utils/use-hash'
import Button from './button'
import CloseIcon from './close-icon'
import EditIcon from './edit-icon'
import get from './get'
import {ObjectContext} from './object-store'
import {useObject} from './object-store'
import {ProductEditorLine} from './product-editor'
import {Rating} from './rating'
import {Resources} from './resource'
import Sheet from './sheet'
import './product-view.scss'


const addingPlaceholder = `XX99... Note...
🍷🍇🫒🦐🍣🍕🍰🍦🐌... Note...`


export function ProductSheet({visible, product, setHash, productSheetPosition, producerSheetPosition, producer, onAddProductRating, ...props}) {
    function close() {
        props.setProductSheetPosition('closed')
        setTimeout(() => setHash(window.location.hash.split('/').slice(0, 2).join('/'), props.params), 200)

    }
    const {dropProps} = useDropResource({
        model: 'product',
        modelId: get(product, 'id'),
        parentClassName: 'product-sheet',
    })
    if (!product) {return null}
    if (!producer) {return null}

    return <Sheet
        header
        extraClassName={bm("product-sheet", {productSheetPosition, producerSheetPosition})}
        position={productSheetPosition}
        onChangePosition={position => {
            if (position === 'closed') {
                close()
            }
        }}
        {...props}
        {...dropProps}
    >
        <ProductHeader
            product={product} onClose={close} producer={producer} onAddProductRating={onAddProductRating}
        />
        <ProductContent product={product} />
    </Sheet>
}


function ProductHeader({product, producer, onClose, onAddProductRating}) {
    const [adding, setAdding] = React.useState(false)
    const {setHashParam} = useHash()

    if (!product) {
        return null
    }

    const s = `${producer.name.replace(/ /g, '+')}+${product.name.replace(/ /g, '+')}`
    const vivinoHref = `https://www.vivino.com/search/wines?q=${s}`
    const wineSearcherHref = `https://www.wine-searcher.com/find/${s}`

    return <>
        <h1 className="producer-view__title producer-view__title--p">
            <span className="producer-view__title__text">
                {product.name}
            </span>
            <Button onClick={onClose} className="icon-button">
                <CloseIcon color="--primary" />
            </Button>
        </h1>
        <div className="product-view__buttons">
            <ObjectContext model="product" id={product.id}>
                <Button
                    onClick={() => setHashParam('edit', `producer/${producer.id}/product/${product.id}`)}
                >
                    <EditIcon color="white"/>
                </Button>
                <ProductEditorLine id={product.id} />
            </ObjectContext>
        </div>
    </>
}

function ProductContent({product}) {
    if (!product) {return null}

    return <div className="product-view__content">
        <Resources ids={product.resources} model="product" />
        {(product.best_after || product.best_at || product.best_before || product.origin) ?
            <div className="product-view__fields">
                <Field product={product} name="best_after" />
                <Field product={product} name="best_at" />
                <Field product={product} name="best_before" />
                <Field product={product} name="origin" />
            </div>
            : null
        }
        {product.ratings && product.ratings.map(id => {
            return <FullRating id={id} />
        })}
        {product.note ?
            product.note
            : null
        }
    </div>
}

export function Field({product, name}) {
    const value = product[name]
    if (!value) {return null}
    return <div className="product-view__field">
        <span className="product-view__field__name">{name.replace(/_|-/g, ' ')} </span>
        <span className="product-view__field__value">{value}</span>
    </div>
}


function FullRating({id}) {
    const rating = useObject(id)
    return <div className="product-view__rating">
        <div className="product-view__rating__header">{rating.year} <Rating id={id} /> <span className="product-view__rating__number">{rating.normalized_rating}</span></div>
        {rating.note ? <p>
            {rating.note}
        </p> : null}
    </div>
}
