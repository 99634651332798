import React from 'react'

import get from './components/get'
import LoadMore from './components/load-more'
import {ObjectContext} from './components/object-store'
import {useField} from './components/object-store'
import {useList} from './components/object-store'
import {useMerge} from './components/object-store'
import {useObject} from './components/object-store'
import {useSaveField} from './components/object-store'
import {useSaveListener} from './components/object-store'
import {useSave} from './components/object-store'
import Page from './components/page'
import PageSavedQueries from './components/page-saved-queries'
import ProducerEditorModal from './components/producer-editor-modal'
import {ProductEditorLine} from './components/product-editor'
import {Field} from './components/product-view'
import QueryBuilder from './components/query-builder'
import {Resources} from './components/resource'
import {productFields} from './fields'
import useDebounce from './hooks/use-debounce'
import useMergeName from './hooks/use-merge-name'
import useProductDropResource from './hooks/use-product-drop-resource'
import Merger from './merger'
import bem from './utils/bem'
import shouldClick from './utils/should-click'
import translateQuery from './utils/translate-query'
import useHash from './utils/use-hash'
import {buildInitialFilter} from './utils/use-hash'
import './components/products-page.scss'
import './page-products.scss'
import {useUpdate} from './components/object-store'
import genId from './utils/gen_id'
import standardRatings from './ratings.json'
import {useWantHaveDoneWorkflow} from './components/product-editor'



const fields = productFields

const interestedInSaved = {
    models: {product: true, product_rating: true, product_tag: true},
}

export default function ProductsPage() {
    const {setHashParam, params} = useHash()
    const className = bem("products-page")

    const savedQuery = useObject(get(params, 'query'))

    const [query, setQuery] = React.useState({
        page: {start: 0, end: 200},
        filter: buildInitialFilter(params),
        sort: [{field: 'product._updated_at', dir: 'DESC'}],
        ...(savedQuery || {}).query,
    })

    React.useEffect(() => {
        function f(e) {
            setHashParam('filter', null)
            setQuery(prev => {
                return {
                    ...prev,
                    filter: buildInitialFilter({}),
                    'fts': '',
                    'product.fts': '',
                    'producer.fts': '',
                    sort: [{field: 'product._updated_at', dir: 'DESC'}],
                    ...(savedQuery || {}).query,
                }
            })
        }

        window.addEventListener('new_query', f)
        return () => {
            window.removeEventListener('new_query', f)
        }
    }, [get(savedQuery)])

    React.useEffect(() => {
        const nextQuery = (savedQuery || {}).query
        setQuery(prev => ({...prev, filter: buildInitialFilter(params), sort: [{field: 'product._updated_at', dir: 'DESC'}], ...nextQuery}))
    }, [savedQuery])

    const tQuery = React.useMemo(() => {
        return translateQuery(query)
    }, [JSON.stringify(query)])
    const dtQuery = useDebounce(tQuery, 100)

    const {ids, refresh, total, spinning} = useList('product', dtQuery)
    useSaveListener(interestedInSaved, refresh)

    const {addToMergeAlways, mainToMergeSelection, addToMerge, selection, cancelMerge} = useMerge('product')
    const {mergeNameSelected, addToMergeName, addToMergeNameAlways} = useMergeName('product')

    const top = <PageSavedQueries
        model="product"
        setQuery={setQuery}
        params={params}
        setHashParam={setHashParam}
        query={query}
        total={total}
    />

    return <Page top={top}>
        <Merger
            selection={selection}
            cancelMerge={cancelMerge}
            model="producer"
        />
        <QueryBuilder
            onUpdate={setQuery}
            value={query}
            fields={fields}
            quickFilterFields={['product_tag.tag', 'product_tag', 'product_rating']}
        />
        <div className="page-products__content">
            {ids.map(id => {
                return <ProductEditor
                    key={id}
                    id={id}
                    onSelect={addToMerge || addToMergeName}
                    selected={mainToMergeSelection === id || mergeNameSelected === id}
                    toggleMergeActive={addToMergeAlways}
                    addToMergeName={addToMergeNameAlways}
                >
                    <ProductRater id={id} />
                </ProductEditor>
            })}
            <LoadMore
                page={query.page}
                total={total}
                spinning={spinning}
                update={setQuery}
            />
        </div>
        <ProducerEditorModal />
    </Page>
}


function ProductEditor({id, ...props}) {
    return <ObjectContext model="product" id={id}>
        <ProductEditor_ id={id} {...props} />
    </ObjectContext>
}


function ProductEditor_({id, selected, onSelect, toggleMergeActive, addToMergeName, children}) {
    const {setHashParam} = useHash()
    const [resources] = useField('resources')
    const [producerId] = useSaveField('producer_id')
    const [name] = useField('name')
    const [producerName] = useField('producer_name')
    const [note] = useField('note')
    const ref = React.useRef()

    function handleClick(event) {
        if (!shouldClick(event)) {return}
        if (onSelect) {
            onSelect(id)
        }
        else {
            window.mapkiModalTarget = ref.current
            setHashParam('edit', `producer/${producerId}/product/${id}`)
        }
    }

    const {dropProps} = useProductDropResource({id, parentClassName: 'editor__e'})

    return <div className={bem('editor__e').m({selected, selectable: !!onSelect})} onClick={handleClick} {...dropProps} ref={ref}>
        {producerName ?
            <div
                className="page-products__product__producer"
                role="button"
                onClick={event => {
                    // if (!shouldClick(event)) {return}
                    setHashParam('edit', `producer/${producerId}`)
                }}
            >
                {producerName}
            </div>
            : null
        }
        <h2>
            {name}
        </h2>
        <div className="editor__s">
            <ProductEditorLine
                toggleMergeActive={toggleMergeActive}
                addToMergeName={addToMergeName}
            />
        </div>
        {resources && resources.length > 0 ?
            <Resources ids={resources} model="product" />
            : null
        }
        {note ?
            <pre>{note}</pre>
            : null
        }
        {children || null}
    </div>
}


function ProductRater({id}) {
    const ratings = [75, 85, ...[...Array(12).keys()].map(x => x + 89)]
    const save = useSave()
    const update = useUpdate()
    const [tags, setTags] = useField('tags')
    const {increaseDone} = useWantHaveDoneWorkflow({productId: id})


    function addRating(source, canonical_rating) {
        const rating = {
            source,
            canonical_rating,
            normalized_rating: standardRatings[source][canonical_rating],
            id: genId(),
            product_id: id,
            timestamp: (new Date()).toISOString().split('T')[0]
        }
        save('product_rating', rating)
        if (source === 'PW') {
            increaseDone()
        }
        update([{
            id,
            ratings: prev => ([rating.id, ...(prev || [])])}]
        )
    }

    return <div className="page-products__rater">
        <div className="page-products__rater__rater">
        PW{ratings.map(r => {
            return <button className="button"
                onClick={() => addRating('PW', r)}
                key={r}
            >
                {r}
            </button>
        })}
        </div>
        <div className="page-products__rater__rater">
        AG{ratings.map(r => {
            return <button className="button"
                onClick={() => addRating('AG', r)}
                key={r}
            >
                {r}
            </button>
        })}
        </div>
    </div>
}
