import React from 'react'

import Svg from '../svg'


export default function RatingsIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path" fill={color || "white"} points="59.8054212 45.9384615 94 45.9384615 94 54.0615385 59.8054212 54.0615385 83.9846398 78.240757 78.240757 83.9846398 54.0615385 59.8054212 54.0615385 94 45.9384615 94 45.9384615 59.8054212 21.759243 83.9846398 16.0153602 78.240757 40.1945788 54.0615385 6 54.0615385 6 45.9384615 40.1945788 45.9384615 16.0153602 21.759243 21.759243 16.0153602 45.9384615 40.1945788 45.9384615 6 54.0615385 6 54.0615385 40.1945788 78.240757 16.0153602 83.9846398 21.759243"></polygon>
        </g>
    </Svg>
}
