import React from 'react'

import Svg from './svg'


export default function ImageIcon({width, height, color}) {
    return <Svg width={width} height={height} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="image" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M15.9756098,4 L84.0243902,4 C90.0860521,4 95,9.16594519 95,15.5384615 L95,82.4615385 C95,88.8340548 90.0860521,94 84.0243902,94 L15.9756098,94 C9.91394787,94 5,88.8340548 5,82.4615385 L5,15.5384615 C5,9.16594519 9.91394787,4 15.9756098,4 Z M16,66 L16,75.6757812 L84.171875,75.6757812 L84.171875,66 L56.9960938,66 L37.515625,38 L16,66 Z M68,44 C73.5228475,44 78,39.5228475 78,34 C78,28.4771525 73.5228475,24 68,24 C62.4771525,24 58,28.4771525 58,34 C58,39.5228475 62.4771525,44 68,44 Z" id="Shape" fill={color || "#000000"}></path>
        </g>
    </Svg>
}
