import React from 'react'

import bm from '../utils/bm'
import Modal from './modal'
import Sheet from './sheet'
import './modal-sheet.scss'


export default function ModalSheet({open, onRequestClose, extraClassName, slot, ...props}) {
    const [visible, setVisible] = React.useState('closed')
    const [prevOpen, setPrevOpen] = React.useState(false)

    const extraClassName_ = bm('modal-sheet', {open, closed: !open, visible}, extraClassName)

    if (open !== prevOpen) {
        if (open) {
            setTimeout(() => setVisible('open'), 0)
        }
        if (!open) {
            setVisible('closing')
            setTimeout(() => setVisible('closed'), 200)
        }
        setPrevOpen(open)
        setVisible(open)
    }
    if (visible === 'closed') {
        return null
    }

    return <Modal open={true} onRequestClose={onRequestClose} extraClassName={extraClassName_}>
        <Sheet
            {...props}
            position={open ? 'open' : 'closed'}
            extraClassName={bm("modal-sheet__sheet", {open, slot, visible})}
            onChangePosition={p => {
                if (p === 'close' || p === 'closed') {
                    onRequestClose()
                }
            }}
        />
    </Modal>
}
