function convertCamelCaseToKebabCase(text) {
    text = text += ''
    return text.replace(
        /\.?([A-Z]+)/g,
        (x, y) =>  '-' + y.toLowerCase()
    ).replace(/^-/, '')
}


export default function bm(
    block,
    modifiers,
    extraClassName,
) {
    if (!modifiers && !extraClassName) {
        return block
    }
    if (!modifiers) {
        return block + ' ' + extraClassName
    }
    const appliedModifiers = Object.keys(modifiers)
        .map(m => {
            const modifier = m + ''
            const flag = modifiers[modifier]
            if (flag === true) {
                return block + '--' + convertCamelCaseToKebabCase(modifier)
            }
            if (flag === false) {
                return null
            }
            return block + '--' + convertCamelCaseToKebabCase(modifier) + '-' + convertCamelCaseToKebabCase(flag + '')
        })
        .filter(v => v)
        .join(' ')
    return [block, appliedModifiers, extraClassName].filter(c => c).join(' ')
}
