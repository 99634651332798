import React from 'react'

import Svg from '../svg'


export default function ShelfIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M95,61 L54,61 L54,39 L95,39 L95,61 Z M95,69 L95,83.4615385 C95,89.8340548 90.0860521,95 84.0243902,95 L54,95 L54,69 L95,69 Z M95,31 L54,31 L54,5 L84.0243902,5 C90.0860521,5 95,10.1659452 95,16.5384615 L95,31 Z M5,46 L5,16.5384615 C5,10.1659452 9.91394787,5 15.9756098,5 L46,5 L46,46 L5,46 Z M5,54 L46,54 L46,95 L15.9756098,95 C9.91394787,95 5,89.8340548 5,83.4615385 L5,54 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
