import React from 'react'

import Svg from '../svg'


export default function MoveToRightIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M14,6 L86,6 C90.418278,6 94,9.581722 94,14 L94,87 C94,91.418278 90.418278,95 86,95 L14,95 C9.581722,95 6,91.418278 6,87 L6,14 C6,9.581722 9.581722,6 14,6 Z M50,83 L87,51 L50,19 L50,83 Z" id="Combined-Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
