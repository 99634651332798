import React from 'react'

import useDebounce from '../hooks/use-debounce'
import useUpdatingRef from '../hooks/use-updating-ref'
import {makeRequest} from '../http'
import genId from '../utils/gen_id'
import get from './get'
import {useSpinning} from './spinning-context'
import './spinning-context.scss'


const ObjectStoreContextProvider = React.createContext();
export const objectStoreController = {}


export function ObjectStore_({children}) {
    const [state, setState] = React.useState({})
    const listenersRef = React.useRef([])
    objectStoreController.state = state

    const trigger = React.useCallback((...args) => {
        listenersRef.current.forEach(listener => {
            if (isListenerInterested(listener.interests, ...args)) {
                listener.callback(...args)
            }
        })
    }, [])

    window.pw_state = state

    return <ObjectStoreContextProvider.Provider value={{state, setState, listenersRef, trigger}}>
        {children}
    </ObjectStoreContextProvider.Provider>
}

export const ObjectStore = React.memo(ObjectStore_)


export function useObjectStore() {
    return React.useContext(ObjectStoreContextProvider)
}

export function useList(model, {filter, page, sort, groupBy, active, x}) {
    const {setSpinning} = useSpinning()
    const {setState} = useObjectStore()
    const abortControllerRef = React.useRef()
    const [ids, setIds] = React.useState([])
    const [total, setTotal] = React.useState(null)
    const [spinning, setLocalSpinnig] = React.useState(false)

    const filterRef = useUpdatingRef(filter)
    const pageRef = useUpdatingRef(page)
    const sortRef = useUpdatingRef(sort)
    const groupByRef = useUpdatingRef(groupBy)
    const activeRef = useUpdatingRef(active)

    const query = React.useMemo(() => {
        return {filter, page, sort, groupBy}
    }, [filter, page, sort, groupBy])

    const debouncedQuery = query

    const refresh = React.useCallback(() => {
        if (activeRef.current === false) {
            return
        }
        setLocalSpinnig(true)
        const filter = filterRef.current
        const page = pageRef.current
        const sort = sortRef.current
        const groupBy = groupByRef.current

        if (abortControllerRef.current) {
            abortControllerRef.current.abort()
        }
        abortControllerRef.current = new AbortController()

        const id = genId()
        setSpinning(prev => {
            return [...prev, {message: 'list', model, id}]
        })
        if (!get(filter, 'items', 0, 'operand') && get(filter, 'items', 'length') > 0) {
        }
        makeRequest(`2/${model}_list`, {
            signal: abortControllerRef.current.signal,
            body: JSON.stringify({filter, page, sort, groupBy}),
        })
            .then(response => {
                updateStateObjects(setState, response.objects)
                setIds(response.order)
                setTotal(response.total)
                setSpinning(prev => prev.filter(s => s.id !== id))
                setLocalSpinnig(false)
            })
            .catch(error => {
                if (error.name === 'AbortError') {
                    setSpinning(prev => prev.filter(s => s.id !== id))
                    return
                }
                console.error(error)
                // window.alert(error)
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.id !== id) {
                            return p
                        }
                        return {...p, error}
                    })
                })
                setLocalSpinnig(false)
            })

        return () => {
            if (abortControllerRef.current) {
                return abortControllerRef.current.abort()
            }
        }
    }, [])

    React.useEffect(() => {
        return refresh()
    }, [debouncedQuery])

    return {ids, refresh, total, spinning}
}

export function updateStateObjects(setState, objects) {
    setState(prev => {
        const next = {...prev}
        objects
            .filter(o => o)
            .forEach(object => {
                const id = object.id
                next[id] = {...get(prev, id)}
                Object.keys(object).forEach(field => {
                    let value = typeof object[field] === 'function' ? object[field](get(prev, id, field), prev) : object[field]
                    if (value === undefined) {
                        value = get(prev, id, field)
                    }
                    next[id][field] = value
                })
            })
        return next
    })
}


const ObjectContextProvider = React.createContext()

export function ObjectContext({id, model, children}) {
    return <ObjectContextProvider.Provider value={{id, model}}>
        {children}
    </ObjectContextProvider.Provider>
}

export function useObjectContext() {
    return React.useContext(ObjectContextProvider)
}


export function useField(field) {
    const {id} = useObjectContext()
    const {state, setState} = useObjectStore()

    const setValue = React.useCallback(value => {
        updateStateObjects(setState, [{id, [field]: value}])
    }, [id, field])

    return [get(state, id, field), setValue]
}


const queue = {}



export function useSaveField(field) {
    const {id, model} = useObjectContext()
    const [value, setValue_] = useField(field)
    const {setSpinning} = useSpinning()
    const {trigger} = useObjectStore()

    const saveFieldValue = React.useCallback(value => {
        const sid = genId()
        setValue_((prev, ...args) => {
            const next = typeof value === 'function' ? value(prev, ...args) : value

            if (get(queue, id, field)) {
                get(queue, id, field).abort()
                queue[id][field] = start(model, id, field, next)
            }
            else {
                setSpinning(prev => [{message: 'save', model, id, field, value: next, sid}, ...prev])
                queue[id] ||= {}
                queue[id][field] ||= start(model, id, field, next)
            }
            return next
        })

        function start(model, id, field, value) {
            const controller = new AbortController()
            makeRequest(`2/${model}_save`, {
                signal: controller.signal,
                body: JSON.stringify({
                    fetch: false,
                    timestamp: new Date().toISOString(),
                    update: {
                        id,
                        [field]: value,
                    }
                })
            })
                .then(() => {
                    setSpinning(prev => {
                        return prev
                            .filter(p => p.sid !== sid)
                    })
                    trigger({model, id, field, value})
                })
                .catch(error => {
                    setSpinning(prev => {
                        return prev
                            .map(p => {
                                if (p.sid == sid) {
                                    return {...p, error}
                                }
                                return p
                            })
                    })
                })
        }

    }, [id, model])

    return [value, saveFieldValue]
}


export function useFocusBlurSaveField(field) {
    const [value, setValue] = useSaveField(field)
    const [value_, setValue_] = React.useState(null)

    const valueRef = useUpdatingRef(value)
    const value_Ref = useUpdatingRef(value_)

    function onFocus() {
        setValue_(value)
    }

    function onChange(event) {
        setValue_(event.target.value)
    }

    function onBlur(event) {
        if (event.target.value != (value || '')) {
            setValue(event.target.value)
        }
        setValue_(null)
    }

    return {
        value: (value_ === null ? value : value_) || '',
        onFocus,
        onChange,
        onBlur,
        name: field,
        setValue,
        placeholder: field,
    }
}

export function useSaves(alert) {
    const {state, setState, trigger} = useObjectStore()
    const {setSpinning} = useSpinning()

    return React.useCallback((model, objects) => {
        const sid = genId()
        setSpinning(prev => [{message: 'saves', model, objects, sid}, ...prev])
        updateStateObjects(setState, objects)
        const controller = new AbortController()

        return makeSaveRequest()
            .then(response => {
                setSpinning(prev => prev.filter(s => s.sid !== sid))
                updateStateObjects(setState, response.objects)
                trigger({model})
                return response
            })
            .catch(error => {
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.sid === sid) {return {...p, error}}
                        return p
                    })
                })
                if (alert !== false) {
                    window.alert(error)
                }
                else {
                    throw error
                }
            })

        function makeSaveRequest() {
            return makeRequest(`2/${model}_saves`, {
                body: JSON.stringify({fetch: true, action: 'save', updates: objects})
            })
        }
    }, [])
}

export function useSave(alert) {
    const {state, setState, trigger} = useObjectStore()
    const {setSpinning} = useSpinning()

    return React.useCallback((model, object) => {
        const sid = genId()
        setSpinning(prev => [{message: 'save', model, object, sid}, ...prev])
        updateStateObjects(setState, [object])
        const controller = new AbortController()

        return makeSaveRequest()
            .then(response => {
                setSpinning(prev => prev.filter(s => s.sid !== sid))
                updateStateObjects(setState, response.objects)
                trigger({model, objects: response.objects})
                return response
            })
            .catch(error => {
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.sid === sid) {return {...p, error}}
                        return p
                    })
                })
                if (alert !== false) {
                    window.alert(error)
                }
                else {
                    throw error
                }
            })

        function makeSaveRequest() {
            if (model !== 'resource') {
                const body = JSON.stringify({fetch: true, update: object})
                return makeRequest(`2/${model}_save`, {body})
            }
            else {
                const form = new FormData()
                form.append('id', object.id)
                fappend(form, 'model', object.model)
                fappend(form, 'model_id', object.model_id)
                fappend(form, 'url', object.url)
                fappend(form, 'rank', object.rank)
                fappend(form, 'color', object.color)
                fappend(form, 'file', object.file)
                fappend(form, 'degrees', object.degrees)
                return makeRequest(`2/${model}_save`, {
                    signal: controller.signal,
                    body: form,
                    contentType: false,
                })
            }
        }
    }, [])
}


function fappend(form, key, value) {
    if (!value) {return}
    form.append(key, value)
}


export function useUpdate() {
    const {state, setState} = useObjectStore()
    return React.useCallback(objects => {
        updateStateObjects(setState, objects)
    }, [])
}

export function useObject(id) {
    const {state} = useObjectStore()
    return get(state, id)

}

export function useGet(model, id) {
    const filter = React.useMemo(() => {
        return {operator: 'AND', items: [{field: model + '.id', operator: '=', operand: id}]}
    }, [id])
    useList(model, {filter, active: !!id})
    return useObject(id)
}

function isListenerInterested(interests, subject) {
    if (!interests) {return true}
    const {model, id, field} = subject
    if (get(interests, 'models', model, field) === true) {
        return true
    }
    if (interests.fields && !interests.fields[field]) {
        return false
    }
    if (interests.objects && !interests.objects[id]) {
        return false
    }
    if (interests.models && !interests.models[model]) {
        return false
    }
    return true
}

export function useSaveListener(interests, callback) {
    const {listenersRef} = useObjectStore()
    React.useEffect(() => {
        const listener = {interests, callback}
        listenersRef.current.push(listener)

        return () => {
            listenersRef.current = listenersRef.current
                .filter(l => l !== listener)
        }
    }, [interests, callback])
}

export function useRemove() {
    const {setState} = useObjectStore()
    return React.useCallback(id => {
        setState(prev => {
            const next = {...prev}
            delete next[id]
            return next
        })
    }, [])
}

export function useTrash() {
    const remove = useRemove()
    const {setSpinning} = useSpinning()
    const {trigger} = useObjectStore()

    return React.useCallback((model, id) => {

        const sid = genId()
        setSpinning(prev => [{message: 'trash', model, id, sid}, ...prev])

        return makeRequest(`2/${model}_trash`, {
            body: JSON.stringify({fetch: true, id})
        })
            .then(response => {
                setSpinning(prev => prev.filter(s => s.sid !== sid))
                trigger({model})
                return response
            })
            .catch(error => {
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.sid === sid) {return {...p, error}}
                        return p
                    })
                })
                window.alert(error)
            })
    }, [])
}



export function useDelete() {
    const remove = useRemove()
    const {setSpinning} = useSpinning()
    const {trigger} = useObjectStore()

    return React.useCallback((model, id) => {
        remove(id)

        const sid = genId()
        setSpinning(prev => [{message: 'delete', model, id, sid}, ...prev])

        return makeRequest(`2/${model}_delete`, {
            body: JSON.stringify({fetch: true, id})
        })
            .then(response => {
                setSpinning(prev => prev.filter(s => s.sid !== sid))
                trigger({model, action: 'delete', id})
                return response
            })
            .catch(error => {
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.sid === sid) {return {...p, error}}
                        return p
                    })
                })
                window.alert(error)
            })
    }, [])
}


export function useDeletes() {
    const remove = useRemove()
    const {setSpinning} = useSpinning()
    const {trigger} = useObjectStore()

    return React.useCallback((model, ids) => {
        ids.forEach(id => remove(id))

        const sid = genId()
        setSpinning(prev => [{message: 'deletes', model, ids, sid}, ...prev])

        return makeRequest(`2/${model}_delete`, {
            body: JSON.stringify({fetch: true, ids})
        })
            .then(response => {
                setSpinning(prev => prev.filter(s => s.sid !== sid))
                trigger({model})
                return response
            })
            .catch(error => {
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.sid === sid) {return {...p, error}}
                        return p
                    })
                })
                window.alert(error)
            })
    }, [])
}


export function useMerge(model, onMerge) {
    const [active, setActive] = React.useState(false)

    const {setSpinning} = useSpinning()
    const {setState, trigger, state} = useObjectStore()

    const [selection, setSelection] = React.useState(null)

    function toggle(id) {
        if (!selection) {
            setSelection(id)
            return
        }
        if (selection === id) {
            setSelection(null)
        }
        else {
            merge(selection, [id])
        }
    }

    function toggleMergeActive() {
        setActive(prev => {
            const next = !prev
            setSelection(null)
            return next
        })
    }

    const merge = React.useCallback((main, others) => {
        others = others.filter(id => id !== main)
        const sid = genId()
        setSpinning(prev => [{message: 'merge', model, main, others, sid}, ...prev])
        if (onMerge) {
            others.forEach(onMerge)
        }
        if (model === 'product') {
            const producersToUpdateId = {}
            others.forEach(id => {
                producersToUpdateId[state[id].producer_id] = true
            })
            const producersUpdates = Object.keys(producersToUpdateId)
                .map(producerId => {
                    return {
                        id: producerId,
                        products: state[producerId].products.filter(productId => others.indexOf(productId) === -1)
                    }
                })
            updateStateObjects(setState, producersUpdates)
        }
        return makeRequest(`2/${model}_merge`, {
            body: JSON.stringify({fetch: true, main, others})
        })
            .then(response => {
                trigger({model, id: main})
                updateStateObjects(setState, response.objects)
                setSpinning(prev => prev.filter(s => s.sid !== sid))
                return response
            })
            .catch(error => {
                setSpinning(prev => {
                    return prev.map(p => {
                        if (p.sid === sid) {return {...p, error}}
                        return p
                    })
                })
            })
    })
    const mergeActive = active || !!selection

    return {
        selection,
        mergeActive,
        toggleMergeActive,
        merge,
        mainToMergeSelection: mergeActive ? selection : null,
        addToMerge: mergeActive ? toggle : null,
        addToMergeAlways: toggle,
        cancelMerge: () => setSelection(null),
    }
}
