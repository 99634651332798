import React from 'react'

import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'
import bem from '../utils/bem'
import Button from './button'
import CalendarAfterIcon from './icons/calendar-after-icon'
import CalendarAtIcon from './icons/calendar-at-icon'
import CalendarBeforeIcon from './icons/calendar-before-icon'
import CalendarIcon from './icons/calendar-icon'
import {useField} from './object-store'
import {useFocusBlurSaveField} from './object-store'
import {align} from './popup-menu'
import {useAlignOnScroll} from './popup-menu'
import Portal from './portal'
import SaveIcon from './icons/save-icon'
import './best-dates-editor.scss'


const className = bem('best-dates-editor')


export default function BestDatesEditor({}) {
    const [valueBestAfter] = useField('best_after')
    const [valueBestAt] = useField('best_at')
    const [valueBestBefore] = useField('best_before')
    const [open, setOpen] = React.useState(false)
    const hasAll = valueBestAfter && valueBestAt && valueBestBefore
    const hasNone = !valueBestAfter && !valueBestAt && !valueBestBefore

    return <>
        <E Icon={CalendarAfterIcon} open={open} placeholder="best after" field="best_after" />
        <E Icon={CalendarAtIcon} open={open}  placeholder="best at" field="best_at" />
        <E Icon={CalendarBeforeIcon} open={open} placeholder="best before" field="best_before"/>
        {open || hasAll ?
            null
            : <Button onClick={() => {
                setOpen(true)}
            }>
                <CalendarIcon color="--on-primary" />
            </Button>
        }
    </>
}


function E({Icon, open, placeholder, field}) {
    const [editing, setEditing] = React.useState(false)
    const ref = React.useRef()
    const sref = React.useRef()
    const [value] = useField(field)
    const valueProps = useFocusBlurSaveField(field)

    useCloseOnClickOutside(editing, () => setEditing(false), sref, ref)
    const {setToAlign} = useAlignOnScroll(ref.current)

    if (!open && (!value || value.length === 0)) {
        return null
    }
    return <>
        <div className={className.e('e')} onClick={() => setEditing(p => !p)} ref={ref} role="button">
            <Icon color="black" width="16px" height="16px" color="--on-content"/> {value}
        </div>
        {editing ?
            <Portal parent={ref.current}>
                <div
                    ref={element => {
                        sref.current = element
                        align(ref.current, element)
                        setToAlign(element)
                    }}
                    className={className.e('l').x("popup-select tag-editor")}
                >
                    <input
                        type="text"
                        ref={element => {
                            if (element) {
                                setTimeout(() => element.focus(), 0)
                            }
                        }}
                        autoFocus
                        placeholder={placeholder}
                        className="popup-editor-input"
                        {...valueProps}
                    />
                    <SaveIcon
                        onClick={() => setEditing(false)}
                        className="sicon-button"
                        role="button"
                        color="--primary"
                    />
                </div>
            </Portal>
            : null
        }
    </>
}
