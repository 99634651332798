import React from 'react'

import Button from './components/button'
import FilterBuilder from './components/filter-builder'
import get from './components/get'
import DeleteIcon from './components/icons/delete-icon'
import {useInit} from './components/init-context'
import {ObjectContext} from './components/object-store'
import {useDelete} from './components/object-store'
import {useField} from './components/object-store'
import {useFocusBlurSaveField} from './components/object-store'
import {useList} from './components/object-store'
import {useSaveField} from './components/object-store'
import {useSaveListener} from './components/object-store'
import {useSave} from './components/object-store'
import Page from './components/page'
import {fields} from './fields'
import './page-templates.scss'


const interestedInSaved = {
    models: {saved_query: true},
}
const sort = [{
    field: "saved_query.name",
    dir: "ASC",
}]


export default function PageTemplates() {
    const init = useInit()
    const save = useSave()

    const {ids, refresh} = useList('saved_query', {sort})
    useSaveListener(interestedInSaved, refresh)

    return <Page extraClassName="page-templates" top={<div />}>
        {ids.map((id, index) => {
            return <ObjectContext model="saved_query" id={id} key={id} >
                <SavedQueryEditor index={index} />
            </ObjectContext>
        })}
    </Page>
}


function SavedQueryEditor({index}) {
    const [open, setOpen] = React.useState(false)
    const nameProps = useFocusBlurSaveField('name')
    const [model] = useField('model')
    const [query, setQuery] = useSaveField('query')
    const [id] = useField('id')

    const q = React.useMemo(() => {
        return JSON.stringify(query, null, 4)
    }, [query])

    const remove = useDelete()


    return <div className="template-editor">
        <div className="spacer spacer--between">
            <h3 role="button" onClick={() => setOpen(prev => !prev)}>{model}</h3>
            <Button onClick={() => remove('saved_query', id)}>
                <DeleteIcon color="white" />
            </Button>
        </div>
        <input type="text" {...nameProps} placeholder="name" autoFocus={index == 0} className="template-editor__name"/>
        {get(query, 'filter') ?
            <FilterBuilder
                value={get(query, 'filter')}
                fields={fields[model]}
                onUpdate={update => {
                    setQuery(prev => ({...prev, filter: update(prev.filter)}))
                }}
            />
            : null
        }
    </div>
}
