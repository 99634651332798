import React from 'react'

import Svg from '../svg'


export default function RotateCwIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M16.9756098,5 L85.0243902,5 C91.0860521,5 96,10.1659452 96,16.5384615 L96,83.4615385 C96,89.8340548 91.0860521,95 85.0243902,95 L16.9756098,95 C10.9139479,95 6,89.8340548 6,83.4615385 L6,16.5384615 C6,10.1659452 10.9139479,5 16.9756098,5 Z M51.0555593,30.6621924 C50.8707979,30.6590407 50.6856062,30.6574577 50.5,30.6574577 C34.7598846,30.6574577 22,42.0417609 22,56.0850104 C22,70.12826 34.7598846,81.5125631 50.5,81.5125631 C66.2401154,81.5125631 79,70.12826 79,56.0850104 L72,56.0850104 C72,66.6790408 62.3741221,75.2671993 50.5,75.2671993 C38.6258779,75.2671993 29,66.6790408 29,56.0850104 C29,45.5725036 38.4783011,37.0351162 50.2262802,36.9043442 L39.0251263,46.8979559 L43.9748737,51.314095 L64.0386147,33.4133291 L43.9748737,15.5125631 L39.0251263,19.9287022 L51.0555593,30.6621924 Z" id="Combined-Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
