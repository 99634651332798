import React from 'react'

import Svg from '../svg'


export default function CalendarIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M95,28 L6,28 L6,23 C6,15.2680135 12.2680135,9 20,9 L20,7 C20,4.790861 21.790861,3 24,3 L27,3 C29.209139,3 31,4.790861 31,7 L31,9 L70,9 L70,7 C70,4.790861 71.790861,3 74,3 L77,3 C79.209139,3 81,4.790861 81,7 L81,9 C88.7319865,9 95,15.2680135 95,23 L95,28 Z M95,32 L95,81 C95,88.7319865 88.7319865,95 81,95 L20,95 C12.2680135,95 6,88.7319865 6,81 L6,32 L95,32 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
