import React from 'react'

import Svg from '../svg'


export default function DeleteIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M21.0618938,21 L15,21 L15,10.1111111 L15.1811534,10.1111111 C15.7524662,8.31560738 17.60964,7 19.8125,7 L35.1761197,7 C35.4967008,4.643657 36.2207072,3 37.0625,3 L62.9375,3 C63.7792928,3 64.5032992,4.643657 64.8238803,7 L80.1875,7 C82.39036,7 84.2475338,8.31560738 84.8188466,10.1111111 L85,10.1111111 L85,21 L78.9381062,21 C78.9789567,21.3275942 79,21.6613397 79,22 L79,89 C79,93.418278 75.418278,97 71,97 L29,97 C24.581722,97 21,93.418278 21,89 L21,22 C21,21.6613397 21.0210433,21.3275942 21.0618938,21 L21.0618938,21 Z M43.4728825,62.2451011 L37.4718749,72.6391512 C41.1553909,74.7763532 45.4347964,76 50,76 C54.5551411,76 58.8257426,74.7817415 62.5037591,72.6532702 L56.5019547,62.2597005 C54.5893862,63.3665056 52.3686734,64 50,64 C47.6209314,64 45.391125,63.3609331 43.4728825,62.2451011 L43.4728825,62.2451011 Z M56.3436933,39.6501892 C60.315438,41.8748603 63,46.1239236 63,51 L75,51 C75,41.6822993 69.9025463,33.5551727 62.3441872,29.2551684 L56.3436933,39.6501892 L56.3436933,39.6501892 Z M37,51.0010049 L37,51 C37,46.2552887 39.5418623,42.1042611 43.3381317,39.8343725 L37.3368423,29.4398344 C29.9543936,33.7853383 25,41.8146281 25.0000001,51.0019349 L37,51.0010049 Z M50,60 C54.9705627,60 59,55.9705627 59,51 C59,46.0294373 54.9705627,42 50,42 C45.0294373,42 41,46.0294373 41,51 C41,55.9705627 45.0294373,60 50,60 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
