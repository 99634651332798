import React from 'react'

import FilterBuilder from './filter-builder'
import get from './get'
import Query from './query'
import RatingQuery from './rating-query'
import SortBuilder from './sort-builder'
import './query-builder.scss'


export default function QueryBuilder({fields, value, onUpdate, quickFilterFields}) {
    return <div className="editor query-builder">
        <Query
            name="Search producers, products..."
            value={get(value, 'fts') || ''}
            onChange={value => onUpdate(prev => ({...prev, 'fts': value}))}
        />
      {/*  <Query
            name="producers..."
            value={get(value, 'producer.fts') || ''}
            onChange={value => onUpdate(prev => ({...prev, 'producer.fts': value}))}
        />
        <Query
            name="products..."
            value={get(value, 'product.fts') || ''}
            onChange={value => onUpdate(prev => ({...prev, 'product.fts': value}))}
        />*/}
        <RatingQuery
            value={get(value, 'product_rating.normalized_rating') || '*'}
            onChange={value => onUpdate(prev => ({...prev, 'product_rating.normalized_rating': value}))}
        />
        <FilterBuilder
            fields={fields}
            value={get(value, 'filter')}
            quickFilterFields={quickFilterFields}
            onUpdate={u => onUpdate(prev => ({...prev, filter: u(prev.filter)}))}
        />
        <SortBuilder
            fields={fields}
            value={get(value, 'sort')}
            onUpdate={u => onUpdate(prev => ({...prev, sort: u(prev.sort)}))}
        />
    </div>
}
