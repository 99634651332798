import React from 'react'

import Svg from './svg'


export default function DoneIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M92.4642718,20.0123876 L45.5667568,66.9099026 L21.6568542,43 L16,48.6568542 L40.7487373,73.4055916 L40.767767,73.386562 L45.5857864,78.2045815 L94.8924534,28.8979145 C94.9636347,29.6864616 95,30.4850515 95,31.2921348 L95,68.7078652 C95,83.2286103 83.2286103,95 68.7078652,95 L31.2921348,95 C16.7713897,95 5,83.2286103 5,68.7078652 L5,31.2921348 C5,16.7713897 16.7713897,5 31.2921348,5 L68.7078652,5 C79.1915568,5 88.2421318,11.1359019 92.4642718,20.0123876 Z" id="Path" fill={color || '#000'}></path>
        </g>
    </Svg>
}
