import React from 'react'


export default function useLocalStorageState(key, initialValue) {
    const [state, setState] = React.useState(get())

    React.useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state))
    }, [state])

    return [state, setState]

    function get() {
        const value = localStorage.getItem(key)
        if (value) {
            return JSON.parse(value)
        }
        return initialValue
    }
}


export function getValue(key, initialValue) {
    const value = localStorage.getItem(key)
    if (value) {
        return JSON.parse(value)
    }
    return initialValue
}