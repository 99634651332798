import React from 'react'

import Svg from './svg'


export default function ProducersIcon({width, height, color, ...props}) {
    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M15.9756098,5 L84.0243902,5 C90.0860521,5 95,10.1659452 95,16.5384615 L95,83.4615385 C95,89.8340548 90.0860521,95 84.0243902,95 L15.9756098,95 C9.91394787,95 5,89.8340548 5,83.4615385 L5,16.5384615 C5,10.1659452 9.91394787,5 15.9756098,5 Z" id="Shape" fill={color || "#000000"}></path>
        </g>
    </Svg>
}
