import React from 'react'

import Svg from '../svg'


export default function Rating1Icon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M50,91 C27.3563253,91 9,72.6436747 9,50 C9,27.3563253 27.3563253,9 50,9 C72.6436747,9 91,27.3563253 91,50 C91,72.6436747 72.6436747,91 50,91 Z M31,50 C35.9705627,50 40,45.9705627 40,41 C40,36.0294373 35.9705627,32 31,32 C26.0294373,32 22,36.0294373 22,41 C22,45.9705627 26.0294373,50 31,50 Z M69,50 C73.9705627,50 78,45.9705627 78,41 C78,36.0294373 73.9705627,32 69,32 C64.0294373,32 60,36.0294373 60,41 C60,45.9705627 64.0294373,50 69,50 Z M25,61 C22.790861,61 21,62.790861 21,65 C21,67.209139 22.790861,69 25,69 L75,69 C77.209139,69 79,67.209139 79,65 C79,62.790861 77.209139,61 75,61 L25,61 Z" id="Combined-Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
