import React from 'react'

import get from '../components/get'
import {useObjectStore} from '../components/object-store'
import {useSave} from '../components/object-store'
import {useUpdate} from '../components/object-store'
import sorted from '../components/sorted'
import {calculateRankBefore} from '../utils/calculate-rank'
import genId from '../utils/gen_id'


export default function useDropResource({model, modelId, parentClassName, onDrop}) {
    const counter = React.useRef(0)
    const save = useSave()
    const update = useUpdate()
    const targetRef = React.useRef()
    const {state} = useObjectStore()

    function handleDrop(event) {
        let firstRank = get(sorted(state[modelId].resources.map(id => state[id]).filter(r => r), r => r.rank)[0], 'rank')
        const resources = Array.from(event.dataTransfer.items)
            .map(i => {
                if (i.kind === 'file') {
                    const file = i.getAsFile()
                    firstRank = calculateRankBefore(firstRank)
                    return {
                        id: genId(),
                        model,
                        model_id: modelId,
                        file,
                        rank: firstRank,
                    }
                }
            })
            .filter(r => r)
        resources.forEach(r => save('resource', r))
        update([{id: modelId, resources: prev => [...resources.map(r => r.id), ...prev]}])
        if (onDrop) {
            onDrop(event, {model, modelId})
        }
    }

    return {
        dropProps: {
            onDrop: event => {
                event.preventDefault()
                event.stopPropagation()
                const parent = getParent(event, parentClassName)
                counter.current = 0
                if (parent) {
                    parent.classList.remove('file-droppable')
                    parent.classList.remove('file-droppable--shift')
                }
                handleDrop(event)
            },
            onDragOver: event => {
                if (event.__mapki_handled) {
                    return
                }
                event.__mapki_handled = true
                const parent = getParent(event, parentClassName)
                if (targetRef.current && targetRef.current !== parent) {
                    targetRef.current.classList.remove('file-droppable')
                }
                targetRef.current = parent
                if (parent) {
                    parent.classList.add('file-droppable')
                    if (event.shiftKey) {
                        parent.classList.add('file-droppable--shift')
                    }
                }
                event.preventDefault()
            },
            onDragEnter: event => {
                if (event.__mapki_handled) {
                    return
                }
                event.__mapki_handled = true
                const parent = getParent(event, parentClassName)
                if (targetRef.current && targetRef.current !== parent) {
                    targetRef.current.classList.remove('file-droppable')
                }
                targetRef.current = parent
                if (parent) {
                    parent.classList.add('file-droppable')
                }
                event.preventDefault()
            },
            onDragLeave: event => {
                if (targetRef.current) {
                    targetRef.current.classList.remove('file-droppable')
                }
                const parent = getParent(event, parentClassName)
                if (parent) {
                    parent.classList.remove('file-droppable')
                    parent.classList.remove('file-droppable--shift')
                }
                event.preventDefault()
            },
        }
    }
}


function getParent(event, parentClassName) {
    let element = event.target
    while (element) {
        if (element.classList.contains(parentClassName)) {
            return element
        }
        element = element.parentElement
    }
}
