export function calculateRankForSortedArray(array, position) {
    if (!array || array.length === 0) {
        return calculateInitialRank()
    }
    position = pruneExtremePositions(position, array.length)

    if (position === 0) {
        return calculateRankBefore(array[0].rank)
    }
    else if (position === array.length) {
        return calculateRankAfter(array[array.length - 1].rank)
    }
    else {
        return calculateRankBetween(
            array[position - 1].rank,
            array[position].rank
        )
    }
}

function pruneExtremePositions(position, size) {
    if (position === null || position === undefined || position >= size) {
        return size
    }
    else if (position < 0) {
        return Math.max(0, size + position)
    }
    return position
}

function calculateInitialRank() {
    return '1'
}

export function calculateRankBefore(rank) {
    if (rank === null || rank === undefined) {
        return calculateInitialRank()
    }
    return '0' + rank
}

export function calculateRankAfter(rank) {
    if (rank[rank.length - 1] === '0') {
        return rank.slice(0, rank.length - 1) + '1'
    }
    return rank + '1'
}

export function calculateRankBetween(rankA, rankC) {
    if (!rankA) {
        return calculateRankBefore(rankC)
    }
    if (!rankC) {
        return calculateRankAfter(rankA)
    }
    rankA += ''
    rankC += ''
    // Calculate rank b, between a and c:
    //
    // b = a + (c - a)/2
    //   = a + c/2 - a/2
    //   = a/2 + c/2
    //   = (a + c)/2
    //
    // I.e. add a to c and divide by two!

    // Rank strings are treated as fractional-parts of binary
    // numbers, so length equalization is achieved by padding the
    // _right_. Also assumes that $r1 < $r2.
    rankA = padRight(rankA, rankC.length, '0')
    rankC = padRight(rankC, rankA.length, '0')

    // ++ "add"
    let carry = 0
    const digits = []
    for (let i = rankA.length - 1; i >= 0; i--) {
        let sum = parseInt(rankA[i], 10) + parseInt(rankC[i], 10) + carry
        if (sum === 0 || sum === 1) {
            carry = 0
        }
        else {
            carry = 1
            sum -= 2
        }
        digits.push(sum)
    }
    // digits are pushed so they are in reversed order
    digits.reverse()

    // >> "divide by two"
    // Always adding final carry (be it a leading 0 or 1)
    // effectively performs the divide by two (right shift for
    // fractional-part of a binary number)!
    const rankB = carry + digits.join('')
    return rankB || calculateInitialRank()
}


function padRight(str, length, char) {
    const padding = []
    for (let i = str.length; i < length; i++) {
        padding.push(char)
    }
    return str + padding.join('')
}
