import React from 'react'

import Svg from '../svg'


export default function MoveToStartIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M94,14.0558606 C93.9134063,14.0955069 93.8312523,14.1481687 93.7564532,14.213846 L53.8558069,49.2485598 C53.8233113,49.2770926 53.7927006,49.3077032 53.7641679,49.3401988 C53.3997697,49.7552078 53.4407979,50.3870421 53.8558069,50.7514402 L93.7564532,85.786154 C93.8312523,85.8518313 93.9134063,85.9044931 94,85.9441394 L94,87 C94,91.418278 90.418278,95 86,95 L14,95 C9.581722,95 6,91.418278 6,87 L6,14 C6,9.581722 9.581722,6 14,6 L86,6 C90.418278,6 94,9.581722 94,14 L94,14.0558606 Z" id="Combined-Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
