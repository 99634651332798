import React from 'react'

import Svg from '../svg'


export default function CloseIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M50,100 C22.3857625,100 0,77.6142375 0,50 C0,22.3857625 22.3857625,0 50,0 C77.6142375,0 100,22.3857625 100,50 C100,77.6142375 77.6142375,100 50,100 Z M50.5,45.7573593 L29.64035,24.8977093 L25.3977093,29.14035 L46.2573593,50 L25.3977093,70.85965 L29.64035,75.1022907 L50.5,54.2426407 L71.35965,75.1022907 L75.6022907,70.85965 L54.7426407,50 L75.6022907,29.14035 L71.35965,24.8977093 L50.5,45.7573593 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
