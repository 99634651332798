export default function translateQuery(query) {
    return {
        ...query,
        sort: query.sort,
        filter: translateFilter(query),
    }
}


function translateFilter(query) {
    return {
        operator: 'AND',
        items: [
            f(query.filter),
            translateProducerTag(query['producer_tag.tag']),
            translateProductTag(query['product_tag.tag']),
            trText('producer.fts', query['producer.fts']),
            trText('product.fts', query['product.fts']),
            translateFts('fts', query['fts']),
            trNormalizedRating(query['product_rating.normalized_rating']),
            translateRatings(query.ratings),
        ].filter(f => f)
    }
}

function trNormalizedRating(value) {
    if (value && value !== '*') {
        return {
            field: 'product_rating.normalized_rating',
            operator: '>=',
            operand: value,
        }
    }
    return null
}


function translateProducerTag(tag) {
    if (!tag) {return null}
    return {
        field: 'producer_tag.tag',
        operator: '=',
        operand: tag,
    }
}

function translateProductTag(tag) {
    if (!tag) {return null}
    return {
        field: 'product_tag.tag',
        operator: '=',
        operand: tag,
    }
}

function f(o) {
    if (!o) {return null}
    if (Object.keys(o).length === 0) {
        return null
    }
    return o
}


function trText(field, text) {
    if (!text) {return null}
    const items = text.split('\n')
        .map(line => {
            return {
                field,
                operator: 'MATCH',
                operand: line,
            }
        })
    if (items.length === 0) {
        return items
    }
    return {
        operator: 'OR',
        items,
    }
}

function translateFts(_, text) {
    if (!text) {return null}
    const models = ['producer', 'product']
    const items = []
    text.split('\n')
        .forEach(line => {
            models.forEach(model => {
                items.push({
                    field: model + '.fts',
                    operator: 'MATCH',
                    operand: line
                })
            })
        })
    if (items.length === 0) {
        return items
    }
    return {
        operator: 'OR',
        items,
    }
}


function translateRatings() {
    return null
}
