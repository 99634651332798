import React from 'react'

import Svg from '../svg'


export default function GotoIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon id="Path-4" fill={color || "white"} points="65 4 65 64 95 34"></polygon>
        <path d="M65,17 C25.4376988,19.5614993 5.67278146,44.7408772 5.70524794,92.5381338 C22.0332031,52.4609375 65,49.0996094 65,50 C65,62.3948579 65,51.3948579 65,17 Z" id="Path-7" fill={color || "white"}></path>
        </g>
    </Svg>
}
