const operators = {
    '=': (a, b) => a == b,
    '!=': (a, b) => a != b,
    '>=': (a, b) => a >= b,
    '<=': (a, b) => a <= b,
    'IS NULL': a => a == null,
    'IS NOT NULL': a => a != null,
    'LIKE': (a, b) => a && b && a.toLowerCase().indexOf(b.toLowerCase()) !== -1,
    'IN': (a, b) => {
        if (Array.isArray(b)) {
            return b.indexOf(a) !== -1
        }
        return b.split(',').indexOf(a) !== -1
    },
    'MATCH': (a, b) => getFScore(a, b) > 0,
}

export function buildMatchFilter({model, filter, state, fields}) {
    if (!filter) {return () => true}
    function matchFilter(filter, id) {
        if (filter.items) {
            if (filter.items.length === 0) {
                return true
            }
            if (filter.operator === 'AND') {
                return filter.items.every(i => matchFilter(i, id))
            }
            if (filter.operator === 'OR') {
                return filter.items.some(i => matchFilter(i, id))
            }
        }
        const object = state[id]
        if (!filter.field) {
            return true
        }
        const fieldModel = filter.field.indexOf('.') !== -1 ? filter.field.split('.')[0] : model
        let fieldName = filter.field.indexOf('.') !== -1 ? filter.field.split('.')[1] : filter.field
        if (fieldName === 'fts') {
            fieldName = 'name'
        }
        if (fieldModel && fieldModel.startsWith(model + '_')) {
            const subOjectsFieldName = fieldModel.slice(model.length + 1, fieldModel.length) + 's'
            const otherIds = object[subOjectsFieldName]
            return otherIds.some(id => operators[filter.operator](state[id][fieldName], filter.operand))
        }
        if (fieldName.startsWith(model + '_') && filter.query && filter.query.filter) {
            const subOjectsFieldName = fieldName.slice(model.length + 1, fieldName.length) + 's'
            const subFilter = buildMatchFilter({model: fieldName, filter: filter.query.filter, state, fields})
            const otherIds = object[subOjectsFieldName]
            return otherIds.some(id => subFilter(id))
        }

        const value = (fieldModel == model || !fieldModel) ? object[fieldName] : undefined // TODO
        if (value === undefined) {return true}
        return operators[filter.operator](value, filter.operand)
    }

    return function match(id) {
        return matchFilter(filter, id)
    }
}



// export function matchFilter(model, filter, state) {
//     if (!filter) {
//         return () => true
//     }
//     return function objectMatchesFilter(id) {
//         if (filter.items) {
//             if (filter.items.length === 0) {
//                 return true
//             }
//             if (filter.operator === 'AND') {
//                 return filter.items.every(i => matchFilter(model, i, state)(id))
//             }
//             if (filter.operator === 'OR') {
//                 return filter.items.some(i => matchFilter(model, i, state)(id))
//             }
//         }
//         if (!filter.field) {
//             return true
//         }
//         if (filter.field == model + '.fts') {
//             const object = state[id]
//             const name = (object.name || '').toLowerCase()
//             const score = getScore(name, filter.operand, filter.operand.toLowerCase(), filter.operand.split(','), filter.operand.split(','))
//             return score > 0
//         }
//         if (filter.field === 'product_rating.normalized_rating') {
//             const object = state[id]
//             const ratings = object.ratings.map(id => state[id])
//             return ratings.some(r => r.normalized_rating >= filter.operand)
//         }
//         if (filter.field === 'product_rating.source') {
//             const object = state[id]
//             const ratings = object.ratings.map(id => state[id])
//             return ratings.some(r => r.source === filter.operand)
//         }
//         if (filter.field === 'product_rating' && filter.operand) {
//             const object = state[id]
//             const ratings = object.ratings.map(id => state[id])
//             return ratings.some(r => {
//                 const x = r.source === filter.operand.source && r.normalized_rating >= filter.operand.normalized_rating
//                 if (filter.operand.timestamp) {
//                     if (!r.timestamp || !r.timestamp.startsWith(filter.operand.timestamp)) {
//                         return false
//                     }
//                 }
//                 return x
//             })
//         }
//         return true
//     }
// }


export function getFScore(name, query) {

    if (!name) {
        return -1
    }
    if (name.indexOf(query) !== -1) {
        return 1000
    }
    const lowerQuery = query.toLowerCase()
    if (name.toLowerCase().indexOf(lowerQuery) !== -1) {
        return 990
    }
    const words = query.split(' ')
    if (words.reduce((acc, v) => acc && name.indexOf(v) !== -1, true)) {
        return 900
    }
    const lowerWords = query.toLowerCase().split(' ')
    if (lowerWords.reduce((acc, v) => acc && name.toLowerCase().indexOf(v) !== -1, true)) {
        return 800
    }
    return 0
}


export function getScore(name, query, lowerQuery, words, lowerWords) {
    if (!name) {
        return -1
    }
    if (name.indexOf(query) !== -1) {
        return 1000
    }
    if (name.toLowerCase().indexOf(lowerQuery) !== -1) {
        return 990
    }
    if (words.reduce((acc, v) => acc && name.indexOf(v) !== -1, true)) {
        return 900
    }
    if (lowerWords.reduce((acc, v) => acc && name.toLowerCase().indexOf(v) !== -1, true)) {
        return 800
    }
    return 0
}