import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'
import useCloseOnClickOutsideWithTouchedCheck from '../hooks/use-close-on-click-outside-with-touched-check'
import standardRatings from '../ratings.json'
import bem from '../utils/bem'
import genId from '../utils/gen_id'
import Button from './button'
import DeleteIcon from './icons/delete-icon'
import RatingsIcon from './icons/ratings-icon'
import SaveIcon from './icons/save-icon'
import Modal from './modal'
import {ObjectContext} from './object-store'
import {useDelete} from './object-store'
import {useField} from './object-store'
import {useFocusBlurSaveField} from './object-store'
import {useObject} from './object-store'
import {useSave} from './object-store'
import {useUpdate} from './object-store'
import {align} from './popup-menu'
import {useAlignOnScroll} from './popup-menu'
import Portal from './portal'
import AddIcon from './icons/add-icon'
import './rating.scss'
import './tag.scss'


export function Rating({id, onClick, innerRef}) {
    const rating = useObject(id)
    if (!rating) {return null}
    return <span
        className={bem("rating").m({source: rating.source, clickable: !!onClick})}
        onClick={onClick}
        role={onClick ? 'button' : undefined}
        tabIndex={onClick ? 0 : undefined}
        ref={innerRef}
    >
        <span className="rating__canonical">
            {getMessage()}
        </span>
        <span className="rating__normalized">
            {rating.normalized_rating}<YearMarker rating={rating} />
        </span>
    </span>

    function getMessage() {
        if ([
            '50 Top Pizza',
            "The World's 50 Best Restaurants",
            "The World's 50 Best Bars",
            "The World's 50 Best Hotels",
            "The World's 50 Best Wineries",
        ].indexOf(rating.source) !== -1) {
            return rating.canonical_rating + ' / ' + rating.source
        }
        if (rating.source == 'Slow Wine') {
            return 'Slow Wine'
        }
        if (rating.source == 'Slow Food') {
            return 'Slow Food'
        }
        if (rating.canonical_rating == 'Tasting report mention') {
            return rating.source + '⭐'
        }
        if (rating.canonical_rating == 'Dove fare colazione') {
            return rating.source + '🥐☕'
        }
        if ([
            '🍷🍷🍷', '🍷🍷', '🍇🍇🍇🍇', '🍇🍇🍇', '🍕🍕🍕', '🍕🍕', '🍦🍦🍦', '🍦🍦', '🍦', '🍰🍰🍰', '🍰🍰', '🥖🥖🥖', '🥖🥖', '✾✾✾', '✾✾', '✾', '🐌',
        ].indexOf(rating.canonical_rating) !== -1) {
            return rating.canonical_rating
        }
        if (rating.canonical_rating === '⭐⭐⭐') {
            return rating.canonical_rating
        }
        if (rating.source === 'Veronelli' && rating.canonical_rating >= 94) {
            return '⭐⭐⭐' + rating.canonical_rating
        }
        return `${rating.source}${rating.canonical_rating}`
    }
}

function YearMarker({rating}) {
    if (!rating.timestamp) {
        return null
    }
    const year = parseInt(rating.timestamp.split('-')[0], 10)
    const c = (new Date()).getFullYear()
    if (year >= c) {
        return '•'
    }
    return null
}


export function AddRatingButton({productId}) {
    const [open, setOpen] = React.useState(false)
    const sref = React.useRef()
    const ref = React.useRef()
    const save = useSave()
    const update = useUpdate()

    const [csource, setCsource] = React.useState('')
    const [ccanonical, setCcanonical] = React.useState('')
    const [cnormalized, setCnormalized] = React.useState('')
    const [ctimestampt, setCtimestampt] = React.useState(new Date().toISOString().split('T')[0])

    const {setToAlign} = useAlignOnScroll(ref.current)
    const className = bem('rating-editor')

    useCloseOnClickOutside(open, () => setOpen(false), sref, ref)

    function addRating(rating) {
        rating.id ||= genId()
        const normalized_rating = rating.normalized_rating || standardRatings[rating.source][rating.canonical_rating]
        const timestamp = rating.timestamp || (new Date()).toISOString().split('T')[0]
        const note = rating.note || ''
        save('product_rating', {
            ...rating,
            normalized_rating,
            timestamp,
            note,
        })
        update([{
            id: productId,
            ratings: prev => ([rating.id, ...(prev || [])])}]
        )
    }

    return <>
        <Button round onClick={() => setOpen(p => !p)} innerRef={ref}>
            <RatingsIcon color="white" />
        </Button>
        {open ?
            <Portal parent={ref.current}>
                <div
                    ref={element => {
                        sref.current = element
                        align(ref.current, element)
                        setToAlign(element)
                    }}
                    onClick={event => event.stopPropagation()}
                    className="popup-select"
                >
                    <div className={className.e('quick')}>
                        <div className={className.e('manual')}>
                            <input type="text" placeholder="source" csource={csource} onChange={e => setCsource(e.target.value)}/>
                            <input type="text" placeholder="canonical" ccanonical={ccanonical} onChange={e => setCcanonical(e.target.value)}/>
                            <input type="text" placeholder="normalized" value={cnormalized} onChange={e => setCnormalized(e.target.value)}/>
                            <input type="text" placeholder="YYYY-MM-DD HH:MM" value={ctimestampt} onChange={e => setCtimestampt(e.target.value)}/>
                            <Button
                                onClick={() => {
                                    addRating({source: csource, canonical_rating: ccanonical, normalized_rating: cnormalized, timestamp: ctimestampt, product_id: productId})
                                }}
                            >
                                <AddIcon color="white" />
                            </Button>
                        </div>
                        {Object.keys(standardRatings).map(source => {
                            return <div key={source}>
                                <span className={className.e('quick').e('source')}>{source}</span>
                                <div className={className.e('quick').e('ratings')}>
                                    {Object.keys(standardRatings[source]).map(canonical_rating => {
                                        return <Button
                                            onClick={() => {
                                                addRating({product_id: productId, source, canonical_rating})
                                                setOpen(false)
                                            }}
                                        >
                                            {canonical_rating} {standardRatings[source][canonical_rating]}
                                        </Button>
                                    })}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </Portal>
            : null
        }
    </>
}


export function PopupRatingEditor({id, ...props}) {
    const [open, setOpen] = React.useState(false)
    const sref = React.useRef()
    const ref = React.useRef()

    const {setToAlign} = useAlignOnScroll(ref.current, null, 'PopupRatingEditor')

    return <>
        <Rating id={id} onClick={() => setOpen(p => !p)} innerRef={ref} />
        {open ?
            <Portal parent={ref.current}>
                <div
                    ref={element => {
                        sref.current = element
                        align(ref.current, element)
                        setToAlign(element)
                    }}
                    className="popup-select"
                >
                    <PopupRatingEditorInner
                        id={id}
                        {...props}
                        onClose={() => setOpen(false)}
                        sref={sref}
                        oref={ref}
                    />
                </div>
            </Portal>
            : null
        }
    </>
}

function PopupRatingEditorInner({id, ...props}) {
    return <ObjectContext id={id} model="product_rating">
        <PopupRatingEditorInner_ id={id} {...props} />
    </ObjectContext>
}

function PopupRatingEditorInner_({id, productId, onClose, sref, oref}) {

    const ref1 = React.useRef()
    const save = useSave()
    const update = useUpdate()
    const del = useDelete()
    React.useEffect(() => {
        if (ref1.current) {
            setTimeout(() => ref1.current.focus(), 0)
        }
    }, [])

    const [source_] = useField('source')
    const [normalized_rating_] = useField('normalized_rating')
    const [canonical_rating_] = useField('canonical_rating')
    const [note_] = useField('note')
    const [timestamp_] = useField('timestamp')

    const sourceProps = useFocusBlurSaveField('source')
    const normalizedRatingProps = useFocusBlurSaveField('normalized_rating')
    const canonicalRatingProps = useFocusBlurSaveField('canonical_rating')
    const noteProps = useFocusBlurSaveField('note')
    const timestampRatingProps = useFocusBlurSaveField('timestamp')

    useCloseOnClickOutside(true, onClose, sref, oref)

    const v_ = {source: source_, normalized_rating: normalized_rating_,
        canonical_rating: canonical_rating_, note: note_, timestamp: timestamp_}
    const v_Ref = React.useRef(v_)
    v_Ref.current = v_

    const v = {source: sourceProps.value, normalized_rating: normalizedRatingProps.value,
        canonical_rating: canonicalRatingProps.value, note: noteProps.value, timestamp: timestampRatingProps.value}
    const vRef = React.useRef(v)
    vRef.current = v

    React.useEffect(() => {
        return () => {
            const update = {}
            Object.keys(vRef.current).forEach(field => {
                const value = vRef.current[field]
                const value_ = v_Ref.current[field]
                if (!value && !value_) {return}
                if (value != value_) {
                    update[field] = value
                }
            })
            if (Object.keys(update).length > 0) {
                save('product_rating', {...update, id})
            }
        }
    }, [])

    function handleDel() {
        onClose()
        update([{id: productId, ratings: prev => {
            if (!prev) {return []}
            const next = prev.filter(pid => pid !== id)
            return next
        }}])
        del('product_rating', id)
    }

    const className = bem('rating-editor')

    return <div className={className}>
        <div className={className.e('top')}>
            <input
                {...sourceProps}
                ref={ref1}
                className="popup-editor-input"
            />
            {id ?
                <Button onClick={handleDel}>
                    <DeleteIcon  color="--on-primary" />
                </Button>
                : null
            }
        </div>
        <input
            {...canonicalRatingProps}
        />
        <input
            {...normalizedRatingProps}
        />
        <input
            {...timestampRatingProps}
        />
        <TextareaAutosize
            className="popup-editor-input"
            style={{minWidth: '20rem'}}
            {...noteProps}
        />
    </div>
}


export function RatingEditor({id, ...props}) {
    return <ObjectContext id={id} model="product_rating">
        <RatingEditor_ id={id} {...props} />
    </ObjectContext>
}


export function RatingEditor_({}) {
    const noteProps = useFocusBlurSaveField('note')
    const sourceProps = useFocusBlurSaveField('source')
    const canonicalRatingProps = useFocusBlurSaveField('canonical_rating')
    const normalizedRatingProps = useFocusBlurSaveField('normalized_rating')
    const timestampRatingProps = useFocusBlurSaveField('timestamp')

    return <div className="editor__ee" >
        <input type="text" {...sourceProps} placeholder="source" />
        <input type="text" {...canonicalRatingProps} placeholder="canonical rating" />
        <input type="text" {...normalizedRatingProps} placeholder="normalized rating" />
        <input type="text" {...timestampRatingProps} placeholder="timestamp" />
        <TextareaAutosize
            {...noteProps}
            placeholder="note..."
        />
    </div>
}
