import React from 'react'
import bem from '../utils/bem'

import './switcher.scss'


export default function Switcher({value, onSwitch, options, grow}) {
    return <div className={bem("switcher").m({grow})}>
        {options.map(option => {
            return <button
                key={option.value}
                onClick={(event) => {
                    if (option.value === value) {
                        onSwitch(event, null)
                    }
                    else {
                        onSwitch(event, option.value, option)
                    }
                }}
                className={bem('switcher__item').m({active: option.value === value}).x('button')}
            >
                {option.message || option.value}
            </button>
        })}
    </div>
}
