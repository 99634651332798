import React from 'react'

import bm from '../utils/bm'
import Button from './button'
import Portal from './portal'
import './popup-menu.scss'
import useCloseOnClickOutside from '../hooks/use-close-on-click-outside'


export default function PopupMenu({
    children,
    message,
    multiValue,
    value,
    onSelect,
    values,
    closeOnSelect,
    onToggle,
    extraClassName,
    disabled,
    square,
    danger,
    innerRef
}) {
    const ref = React.useRef(null)
    if (innerRef) {
        innerRef.current = ref.current
    }
    const menuRef = React.useRef(null)
    const [open, setOpen_] = React.useState(false)
    const {setToAlign} = useAlignOnScroll(ref.current)


    function setOpen(v) {
        if (v === false || v === true) {
            setOpen_(v)
            if (onToggle) {
                onToggle(v)
            }
        }
        else {
            setOpen_(p => {
                const n = v(p)
                if (onToggle) {
                    onToggle(n)
                }
                return n
            })
        }
    }

    useCloseOnClickOutside(open, () => setOpen(false), menuRef, ref)

    React.useEffect(() => {
        if (!open) {
            return
        }
        const update = () => {}
        let element = ref.current
        const elements = []
        while(element) {
            element.addEventListener('scroll', update)
            elements.push(element)
            element = element.parentElement
        }
        return () => {
            elements.forEach(element => {
                element.removeEventListener('scroll', update)
            })
        }

    }, [open])

    function _ref(element) {
        menuRef.current = element
        if (!element) {
            return
        }
        setToAlign(element)
        setTimeout(() => {
            if (!ref.current) {return}
            const rect = ref.current.getBoundingClientRect()
            const eRect = element.getBoundingClientRect()
            const height = eRect.height
            const width = eRect.width
            let top = rect.top + rect.height + 1
            let left = rect.left
            if (top + height < window.innerHeight) {
                element.style.top = top + 'px'
            }
            else {
                element.style.top = (rect.top - height - 1) + 'px'
            }

            if (left + width < window.innerWidth) {
                element.style.left = left + 'px'
            }
            else {
                element.style.left = (rect.left - width + rect.width) + 'px'

            }
            element.style.opacity = 1
        }, 0)
    }

    if (values.length === 0) {return null}

    return <>
        <Button innerRef={ref} onClick={() => setOpen(p => !p)} active={open} extraClassName={extraClassName} disabled={disabled} square={square} danger={danger}>
            {children || message || value}
        </Button>
        {open ?
            <Portal parent={ref.current}>
                <ul className={bm("popup-menu", {open})} ref={_ref}>
                    {values.map(v => {
                        return <li
                            key={v.value || v}
                            className={bm("popup-menu__item", {danger: v.danger, active: v.value === value || (multiValue && multiValue.indexOf(v.value) !== -1)})}
                            role="button"
                            onClick={() => {
                                if (v.value !== undefined) {
                                    onSelect(v.value)
                                }
                                else {
                                    onSelect(v)
                                }
                                if (closeOnSelect) {
                                    setOpen(false)
                                }
                            }}>
                            {v.icon || null} {v.message || v}
                        </li>
                    })}
                </ul>
            </Portal>
            : null
        }
    </>
}


export function align(toAlignTo, toAlign, options) {
    if (!toAlignTo) {return}
    if (!toAlign) {return}
    setTimeout(() => {
        const rect = toAlignTo.getBoundingClientRect()
        const eRect = toAlign.getBoundingClientRect()
        const height = eRect.height
        const width = eRect.width
        let top = rect.top
        if (!options || options.vanchor !== 'top') {
            top += rect.height + 1
        }
        let left = rect.left
        toAlign.style.maxWidth = (window.innerWidth - 10) + 'px'
        if (options && options.dontChangeWidth) {
            toAlign.style.width = eRect.width + 'px'
        }
        if (options == 'match') {
            toAlign.style.left = rect.left + 'px'
            toAlign.style.width = rect.width + 'px'
            toAlign.style.top = rect.top + 'px'
            toAlign.style.height = rect.height + 'px'
            toAlign.style.opacity = 1
            return
        }
        if (options == 'match-width') {
            toAlign.style.width = rect.width + 'px'
        }
        if (top + height < window.innerHeight) {
            toAlign.style.top = top + 'px'
            toAlign.style.maxHeight = (window.innerHeight - top) + 'px'
            toAlign.style.overflow = 'auto'
        }
        else if (rect.top > height) {
            toAlign.style.top = (rect.top - height - 1) + 'px'
        }
        else {
            toAlign.style.top = top + 'px'
            toAlign.style.maxHeight = (window.innerHeight - top - 5) + 'px'
            toAlign.style.overflow = 'auto'
        }

        if (left + width < window.innerWidth) {
            toAlign.style.left = Math.max(left, 5) + 'px'
        }
        else {
            toAlign.style.left = Math.max(5, rect.left - width + rect.width) + 'px'
        }
        if (options && options.width) {
            toAlign.style.minWidth = rect.width + 'px'
        }
        toAlign.style.opacity = 1
    }, 0)
}


export function useAlignOnScroll(toAlignTo, options, x) {
    const [toAlign, setToAlign] = React.useState(null)


    React.useEffect(() => {
        if (!toAlign) {return}


        function update() {
            align(toAlignTo, toAlign, options)
        }

        let element = getParent(toAlign)

        const toRemove = []
        while (element) {
            toRemove.push(element)
            element.addEventListener('scroll', update)
            element = getParent(element)
        }


        return () => {
            toRemove.forEach(e => e.removeEventListener('scroll', update))
        }

        function getParent(element) {
            if (element === window) {return null}
            const parent = element.__portalParent || element.parentElement
            if (parent && parent.classList.contains('modal')) {return null}
            return parent || window
        }

    }, [toAlignTo, toAlign, options])

    return {setToAlign}
}