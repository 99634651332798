import React from 'react'

import Svg from '../svg'


export default function TrashcanIcon({width, height, color, ...props}) {
    color = getComputedStyle(document.documentElement)
        .getPropertyValue(color || '--content-color') || color

    return <Svg width={width || '16px'} height={height || '16px'} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="sort" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M21.0618938,21 L15,21 L15,10.1111111 L15.1811534,10.1111111 C15.7524662,8.31560738 17.60964,7 19.8125,7 L35.1761197,7 C35.4967008,4.643657 36.2207072,3 37.0625,3 L62.9375,3 C63.7792928,3 64.5032992,4.643657 64.8238803,7 L80.1875,7 C82.39036,7 84.2475338,8.31560738 84.8188466,10.1111111 L85,10.1111111 L85,21 L78.9381062,21 C78.9789567,21.3275942 79,21.6613397 79,22 L79,89 C79,93.418278 75.418278,97 71,97 L29,97 C24.581722,97 21,93.418278 21,89 L21,22 C21,21.6613397 21.0210433,21.3275942 21.0618938,21 L21.0618938,21 Z M48,25 L48,83 L52,83 L52,25 L48,25 Z M30,25 L30,83 L34,83 L34,25 L30,25 Z M66,25 L66,83 L70,83 L70,25 L66,25 Z" id="Shape" fill={color || "white"}></path>
        </g>
    </Svg>
}
