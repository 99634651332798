import React from 'react'
import Button from './components/button'
import './page-login.scss'


export default function LoginPage() {
    const [token, setToken] = React.useState('')

    return <div className="login-page">
        <div className="page-login__inner">
            <input
                className="token-input"
                placeholder="Token..."
                type="password"
                value={token}
                autoFocus
                onChange={e => setToken(e.target.value)}
                autoFocus
            />
            <Button
                onClick={() => {
                    localStorage.setItem('mapki-token', token)
                    window.location.hash = ''
                    window.location.reload()
                }}
            >
                Login
            </Button>
        </div>
    </div>
}
